import { SignJWT } from 'jose'
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'

import { useAuthorizerConnection } from '../../../api/connections'
import { useConfig } from '../../../services/ConfigProvider'
import { useIdentity } from '../../../services/IdentityProvider'
import { Tenant } from '../../../types/local/tenant'

interface ApiDocsDropdownItemProps {
  // We should not attempt to load connections if there's no tenant, so
  // we require a valid tenant as proof that the tenant exists
  tenant: Tenant
}

const ApiDocsDropdownItem = ({ tenant }: ApiDocsDropdownItemProps) => {
  const [docsUrl, setDocsUrl] = useState<string | null>(null)
  const { getAccessToken, user } = useIdentity()
  const { data: authorizerConnectionData } = useAuthorizerConnection()
  const authorizerApiKey = authorizerConnectionData?.result?.config?.api_key
  const { readmeIoKey } = useConfig()

  useEffect(() => {
    async function loadUrl() {
      const token = await getAccessToken()

      const userObject = {
        name: user?.name,
        email: user?.email,
        AuthorizerAPIKey: `basic ${authorizerApiKey}`,
        JWT: token,
        TenantID: tenant.id,
      }

      // sign the token with the readme.io key. the key must be non-empty
      // a signature with the "wrong" key will simply result in a non-logged in user
      const authToken = await new SignJWT(userObject)
        .setProtectedHeader({ alg: 'HS256' })
        .sign(new TextEncoder().encode(readmeIoKey))

      const readmeUrl = 'https://aserto.readme.io/'

      setDocsUrl(`${readmeUrl}?auth_token=${authToken}`)
    }
    loadUrl()
  }, [tenant, authorizerApiKey, user, getAccessToken, readmeIoKey])

  if (docsUrl === null) {
    return null
  }

  return (
    <Dropdown.Item onClick={() => window.open(docsUrl, '_blank')}>
      <i className="fa fa-book mr-3" /> API Docs
    </Dropdown.Item>
  )
}

export default ApiDocsDropdownItem
