import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const OfflineContent = styled.div`
  height: 31px;
  width: 304px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: fixed;
  // 50% of the viewport minus half of the size of the element
  left: calc(50% - 152px);
  top: 0;
  font-weight: bold;
  font-size: 14px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: 11;
  color: ${({ theme }) => theme.grey100};
  background-color: ${({ theme }) => theme.mojo100};
  @media (max-width: 720px) {
    width: 100%;
    height: 16px;
    font-size: 12px;
    left: 0;
  }
`

const OfflineBanner = () => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine)

  useEffect(() => {
    const updateNetwork = () => {
      setIsOnline(window.navigator.onLine)
    }

    window.addEventListener('offline', updateNetwork)
    window.addEventListener('online', updateNetwork)

    return () => {
      window.removeEventListener('offline', updateNetwork)
      window.removeEventListener('online', updateNetwork)
    }
  }, [])

  return isOnline ? null : <OfflineContent>Seems like you're offline :(</OfflineContent>
}

export default OfflineBanner
