import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { QueryClient, QueryClientProvider, useQueryClient } from 'react-query'

import { useAccount } from '../../api/account'
import { useAonaIdentity, useAonaUser } from '../../api/aona'
import { User } from '../../types/local/authorizer'
import { QueryKeys, reactQueryDefaultOptions } from '../../types/local/general'
import { Account, Tenant } from '../../types/local/tenant'
import { useAserto } from '../../utils/backendapi'
import CreateTenant from '../../views/CreateTenant'
import { useShowError } from '../ErrorModalProvider'
import { useIdentity } from '../IdentityProvider'
import { useStorage } from '../StorageProvider'

export type ProfileContextProps = {
  account: Account | null
  invalidateAccountCache: () => void
  tenant: Tenant | null
  setTenantId: (id: string, navigateToUrl?: string) => void
  aonaUser: User | null
}

const ProfileContext = React.createContext<ProfileContextProps>({
  account: null,
  invalidateAccountCache: () => {},
  tenant: null,
  setTenantId: () => {},
  aonaUser: null,
})

export const useProfile = () => {
  return useContext(ProfileContext)
}

const ProfileAndQueryClientProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const showError = useShowError()
  const { user } = useIdentity()
  const [queryClient] = useState<QueryClient>(
    new QueryClient({
      defaultOptions: {
        queries: {
          ...reactQueryDefaultOptions,
          onError: undefined,
        },
      },
    })
  )
  const outerQueryClient = useQueryClient()
  const { init: initAserto } = useAserto()
  const { data: accountData, error: accountError } = useAccount()
  const { data: aonaIdentity, error: aonaIdentityError } = useAonaIdentity({ identity: user?.sub })
  const { data: aonaUserData, error: aonaUserError } = useAonaUser({
    id: aonaIdentity?.id,
  })
  const aonaUser = useMemo(() => aonaUserData?.result, [aonaUserData?.result])
  const account = useMemo(() => accountData?.result, [accountData?.result])

  const [tenantId, setTenantId] = useStorage<string | undefined>('tenantId', undefined)

  useEffect(() => {
    if (!!account && !!tenantId) {
      if (!getTenantById(account.tenants || [], tenantId)) {
        setTenantId(undefined)
      }
    }
  }, [account, setTenantId, tenantId])

  useEffect(() => {
    if (!!account && !tenantId) {
      setTenantId(selectTenant(account)?.id)
    }
  }, [account, setTenantId, tenantId])

  const tenant = useMemo(() => {
    if (!!account && !!tenantId) {
      return getTenantById(account.tenants || [], tenantId)
    }
  }, [account, tenantId])

  const hasError = !!accountError || !!aonaIdentityError || !!aonaUserError
  const isLoading = !accountData || !aonaIdentity || !aonaUserData

  useEffect(() => {
    if (hasError) {
      showError(accountError ?? aonaIdentityError ?? aonaUserError)
    }
  }, [accountError, aonaIdentityError, aonaUserError, hasError, showError])

  useEffect(() => {
    if (!!tenant?.id) {
      initAserto(tenant.id).catch((error) => showError(error))
    }
  }, [initAserto, showError, tenant?.id])

  const setTenantIdExternal = useCallback(
    (id: string, navigateToUrl?: string) => {
      outerQueryClient.clear()
      queryClient.clear()
      setTenantId(id)
      window.location.href = navigateToUrl ?? '/'
    },
    [outerQueryClient, queryClient, setTenantId]
  )

  const invalidateAccountCache = useCallback(() => {
    outerQueryClient.invalidateQueries(QueryKeys.Account)
  }, [outerQueryClient])

  const value: ProfileContextProps = useMemo(
    () => ({
      account: account || null,
      invalidateAccountCache,
      tenant: tenant || null,
      setTenantId: setTenantIdExternal,
      aonaUser: aonaUser || null,
    }),
    [account, aonaUser, invalidateAccountCache, setTenantIdExternal, tenant]
  )

  if (!!account && !account.personal_tenant) {
    return (
      <ProfileContext.Provider value={value}>
        <QueryClientProvider client={queryClient}>
          <CreateTenant />
        </QueryClientProvider>
      </ProfileContext.Provider>
    )
  }

  if (hasError || isLoading) {
    return null
  }

  return (
    <ProfileContext.Provider value={value}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </ProfileContext.Provider>
  )
}

export const MemoryProfileAndQueryClientProvider: React.FC<PropsWithChildren<ProfileContextProps>> =
  ({ children, ...value }) => {
    return (
      <ProfileContext.Provider value={value}>
        <QueryClientProvider client={new QueryClient()}>{children}</QueryClientProvider>
      </ProfileContext.Provider>
    )
  }

const selectTenant = (account: Account): Tenant | undefined => {
  if (account.default_tenant) {
    const defaultTenant = getTenantById(account.tenants || [], account.default_tenant)

    if (defaultTenant) {
      return defaultTenant
    }
  }

  return account.tenants?.[0]
}

const getTenantById = (tenants: Array<Tenant>, id: string): Tenant => {
  return tenants.find((tenant: Tenant) => tenant.id === id) as Tenant
}

export default ProfileAndQueryClientProvider
