export type VisualState = {
  errorMessage: string | undefined
  isClaimable: boolean
  isClaimed: boolean
  isEditable: boolean
  isLoading: boolean
  isUnavailable: boolean
  isValid: boolean
  kind: string
}

const visualStates = {
  CLAIMED: {
    errorMessage: undefined,
    isClaimable: false,
    isClaimed: true,
    isEditable: false,
    isLoading: false,
    isUnavailable: false,
    isValid: true,
    kind: 'claimed',
  },
  CLAIMING: {
    errorMessage: undefined,
    isClaimable: false,
    isClaimed: false,
    isEditable: false,
    isLoading: true,
    isUnavailable: false,
    isValid: true,
    kind: 'claiming',
  },
  CLAIMING_FAILED: {
    errorMessage: 'Something went wrong',
    isClaimable: false,
    isClaimed: false,
    isEditable: false,
    isLoading: false,
    isUnavailable: false,
    isValid: true,
    kind: 'claiming_failed',
  },
  INVALID: {
    errorMessage:
      'Start with a letter. Must be 4 to 30 lowercase alphanumeric characters or dashes.',
    isClaimable: false,
    isClaimed: false,
    isEditable: true,
    isLoading: false,
    isUnavailable: false,
    isValid: false,
    kind: 'invalid',
  },
  INVALID_RESERVED: {
    errorMessage: 'ASERTO is a reserved word and cannot be used.',
    isClaimable: false,
    isClaimed: false,
    isEditable: true,
    isLoading: false,
    isUnavailable: true,
    isValid: false,
    kind: 'reserved',
  },
  INVALID_UNAVAILABLE: {
    errorMessage: 'That name is already in use.',
    isClaimable: false,
    isClaimed: false,
    isEditable: true,
    isLoading: false,
    isUnavailable: true,
    isValid: false,
    kind: 'unavailable',
  },
  INVALID_UNKNOWN: {
    errorMessage: 'Something is not right with your tenant name.',
    isClaimable: false,
    isClaimed: false,
    isEditable: true,
    isLoading: false,
    isUnavailable: false,
    isValid: false,
    kind: 'unknown',
  },
  INVALID_MARKED_DELETED: {
    errorMessage: 'The name is marked for deletion.',
    isClaimable: false,
    isClaimed: false,
    isEditable: true,
    isLoading: false,
    isUnavailable: true,
    isValid: false,
    kind: 'marked_deleted',
  },
  VALID: {
    errorMessage: undefined,
    isClaimable: true,
    isClaimed: false,
    isEditable: true,
    isLoading: false,
    isUnavailable: false,
    isValid: true,
    kind: 'valid',
  },
  VALID_FRAGMENT: {
    errorMessage: undefined,
    isClaimable: false,
    isClaimed: false,
    isEditable: true,
    isLoading: false,
    isUnavailable: false,
    isValid: true,
    kind: 'valid',
  },
} as const

export const VisualStates: { [key in keyof typeof visualStates]: VisualState } = {
  ...visualStates,
}
