import { useQueryClient } from 'react-query'

import { Ds0User, parseAsDs0UserProperties } from '../types/directoryProperties/Ds0User'
import { useGetAccountForUserIdQuery, useUpdateObjectMutation } from '../types/graphql/ds0Generated'
import { useAccount } from './account'
export type { SetObjectInput } from '../types/graphql/ds0Generated'

export const useDs0AccountProperties = (): Ds0User | undefined => {
  const { data: accountData } = useAccount()
  const accountId = accountData?.result?.id
  const user = useGetAccountForUserIdQuery({ id: accountId! }, { enabled: !!accountId }).data
    ?.object.relations.nodes[0]?.source
  if (!user) {
    return undefined
  }

  return {
    ...user,
    properties: parseAsDs0UserProperties(user.properties),
  }
}

export const useUpdateDs0AccountProperties = (
  options?: Parameters<typeof useUpdateObjectMutation>[0]
) => {
  const queryClient = useQueryClient()
  const { data: accountData } = useAccount()
  const accountId = accountData?.result?.id
  const queryKey = useGetAccountForUserIdQuery.getKey({ id: accountId || '' })
  return useUpdateObjectMutation({
    ...options,
    onSuccess: (d, v, c) => {
      queryClient.invalidateQueries(queryKey)
      options?.onSuccess?.(d, v, c)
    },
  })
}
