import { assertUnreachable } from '../../lib/types'

export type ModalType = {
  type: 'CREATE_ORGANIZATION'
}

export interface AppState {
  currentModal: ModalType | null
}

export type AppAction = { type: 'SET_CURRENT_MODAL'; modal: ModalType | null }

export function reducer(state: AppState, action: AppAction): AppState {
  switch (action.type) {
    case 'SET_CURRENT_MODAL': {
      const { modal } = action
      return { ...state, currentModal: modal }
    }
    default:
      assertUnreachable(action.type)
  }
}

export function getInitialState(): AppState {
  return { currentModal: null }
}
