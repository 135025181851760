import React from 'react'
import styled from 'styled-components'

import Spinner from './Spinner'

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.primaryBlack};
  opacity: 0.85;
  z-index: 8;
  height: 100%;
  top: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`

const SpinnerFull = () => (
  <SpinnerContainer data-testid="spinner-full">
    <Spinner />
  </SpinnerContainer>
)

export default SpinnerFull
