import React, { PropsWithChildren } from 'react'
// This is the project's abstraction around the auth0 APIs, so disabling protection
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Auth0Provider } from '@auth0/auth0-react'

import { useEnvConfig } from '../EnvConfigProvider'
import IdentityProvider, { useOnRedirectCallback } from '../IdentityProvider'

const Auth0IdentityProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { audience, clientId, domain } = useEnvConfig()
  const onRedirectCallback = useOnRedirectCallback()
  return (
    <Auth0Provider
      audience={audience}
      cacheLocation="localstorage"
      clientId={clientId}
      domain={domain}
      redirectUri={window.location.href}
      onRedirectCallback={onRedirectCallback}
    >
      <IdentityProvider>{children}</IdentityProvider>
    </Auth0Provider>
  )
}

export default Auth0IdentityProvider
