import styled from 'styled-components'

export const LottieImg = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
`

export const ImageContainer = styled.div`
  width: 50%;
  margin-left: auto;
  margin-right: auto;
`

export const CenterContainer = styled.div`
  text-align: center;
`
