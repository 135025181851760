import pick from 'lodash/pick'
import { useCallback } from 'react'

import { useAddConnection } from '../../api/connections'
import { AddConnectionModalFormContent } from '../../components/connections/AddConnectionModal'
import { Provider } from '../../types/local/tenant'

export type basicAuthConnectionMakerParams = {
  onSuccess?: () => void
  setIsLoading?: (isLoading: boolean) => void
  setCreatedConnectionId?: (connectionId: string) => void
}

export const useBasicAuthConnectionMaker = ({
  onSuccess,
  setIsLoading,
  setCreatedConnectionId,
}: basicAuthConnectionMakerParams) => {
  const { mutate: addConnection } = useAddConnection({
    onMutate: () => {
      setIsLoading?.(true)
    },
    onSettled: () => {
      setIsLoading?.(false)
    },
    onSuccess: (r) => {
      setCreatedConnectionId?.(r?.id || '')
      onSuccess?.()
    },
  })

  return useCallback(
    ({
      content,
      selectedProvider,
    }: {
      content: AddConnectionModalFormContent
      selectedProvider: Provider
    }) => {
      const keysToPick = selectedProvider.config!.map((key) => key.name!)!
      const config = pick(content, keysToPick)

      addConnection({
        config,
        name: content.name,
        description: content.description,
        kind: selectedProvider?.kind,
        provider_id: selectedProvider?.id,
      })
    },
    [addConnection]
  )
}
