import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useDs0AccountProperties, useUpdateDs0AccountProperties } from '../../api/ds0Directory'
import { useListPolicyBuilders } from '../../api/policyBuilder'
import { useProfile } from '../../services/ProfileAndQueryClientProvider'
import { Ds0User } from '../../types/directoryProperties/Ds0User'
import { useDispatchContext } from '../../ui/state/context/DispatchContext'
import useSampleConnections from './useSampleConnections'

export enum GettingStartedStep {
  ConnectRealIdp = 'connect_real_idp',
  CreateOrganization = 'create_organization',
  DownloadTodoApp = 'download_todo_app',
  PaaCToDoV1Instance = 'paac_todo',
  TryToDoV1Instance = 'try_todo',
}

type DoGettingStartedStep = Record<GettingStartedStep, (() => void) | undefined>

const useGettingStartedState = () => {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { account, tenant } = useProfile()
  const { data: connectedPolicyBuilders } = useListPolicyBuilders({
    policyOrganization: tenant?.name,
    policyRepo: 'policy-todo',
  })

  const { sampleConnections } = useSampleConnections({
    setIsLoading,
  })
  const dispatchContext = useDispatchContext()
  const ds0User = useDs0AccountProperties()
  const { mutate: updateDs0User, isIdle: isUpdateDs0UserIdle } = useUpdateDs0AccountProperties()
  const ds0Steps = ds0User?.properties.getting_started.steps
  const setComplete = useCallback(
    (user: Ds0User, key: GettingStartedStep) => {
      const step = {
        ...user.properties.getting_started.steps[key],
        completed: true,
      }

      user.properties.getting_started.steps[key] = step

      updateDs0User({ object: user })
    },
    [updateDs0User]
  )

  useEffect(() => {
    !!ds0User &&
      isUpdateDs0UserIdle &&
      !ds0Steps?.[GettingStartedStep.ConnectRealIdp]?.completed &&
      !!sampleConnections?.PersonalIdp &&
      setComplete(ds0User, GettingStartedStep.ConnectRealIdp)
  }, [ds0Steps, ds0User, isUpdateDs0UserIdle, sampleConnections?.PersonalIdp, setComplete])

  useEffect(() => {
    !!ds0User &&
      isUpdateDs0UserIdle &&
      !ds0Steps?.[GettingStartedStep.CreateOrganization] &&
      !!account?.tenants &&
      account.tenants.length > 1 &&
      setComplete(ds0User, GettingStartedStep.CreateOrganization)
  }, [account?.tenants, ds0Steps, ds0User, isUpdateDs0UserIdle, setComplete])

  useEffect(() => {
    !!ds0User &&
      isUpdateDs0UserIdle &&
      !ds0Steps?.[GettingStartedStep.PaaCToDoV1Instance] &&
      !!connectedPolicyBuilders?.results &&
      connectedPolicyBuilders?.results.length > 0 &&
      setComplete(ds0User, GettingStartedStep.PaaCToDoV1Instance)
  }, [connectedPolicyBuilders?.results, ds0Steps, ds0User, isUpdateDs0UserIdle, setComplete])

  const doGettingStartedStep: DoGettingStartedStep = {
    [GettingStartedStep.ConnectRealIdp]: () =>
      navigate('/ui/directory?showAddConnectionModal=true'),
    [GettingStartedStep.CreateOrganization]: () =>
      dispatchContext({ type: 'SET_CURRENT_MODAL', modal: { type: 'CREATE_ORGANIZATION' } }),
    [GettingStartedStep.PaaCToDoV1Instance]: () =>
      navigate(
        `/ui/images/${sampleConnections?.Apcr?.id}/${tenant?.name}/policy-todo/associate?template=policy-todo`
      ),
    [GettingStartedStep.TryToDoV1Instance]: () => {
      !!ds0User && setComplete(ds0User, GettingStartedStep.TryToDoV1Instance)
      navigate('/ui/getting-started/todo-policy')
    },
    [GettingStartedStep.DownloadTodoApp]: () => {
      !!ds0User && setComplete(ds0User, GettingStartedStep.DownloadTodoApp)
      navigate('/ui/getting-started/todo-app-download')
    },
  }

  return {
    stepStatus: ds0Steps,
    doGettingStartedStep,
    isLoading,
  }
}

export default useGettingStartedState
