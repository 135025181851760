/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  QueryFunctionContext,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query'

import { useFetchFromDs0 } from '../../clients/CentralDirectoryClient'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Cursor: unknown
  Hash: unknown
  Map: unknown
  Time: unknown
  TypeID: unknown
}

/** Result of the deleteObject mutation. */
export type DeleteObjectResult = {
  __typename?: 'DeleteObjectResult'
  /** True if the object was deleted. False if it was not found. */
  deleted: Scalars['Boolean']
}

/** Result of a DeleteObjectType mutation. */
export type DeleteObjectTypeResult = {
  __typename?: 'DeleteObjectTypeResult'
  /** True if the object type was deleted. False if it was not found. */
  deleted: Scalars['Boolean']
}

export type DeletePermissionResult = {
  __typename?: 'DeletePermissionResult'
  /** Whether or not the permission was deleted. */
  deleted: Scalars['Boolean']
}

/** Result of the deleteRelation mutation. */
export type DeleteRelationResult = {
  __typename?: 'DeleteRelationResult'
  /** Whether or not the relation was deleted. */
  deleted: Scalars['Boolean']
}

export type DeleteRelationTypePermissionResult = {
  __typename?: 'DeleteRelationTypePermissionResult'
  /** Whether or not the permission was deleted. */
  deleted: Scalars['Boolean']
}

/** Result of the deleteRelationType mutation. */
export type DeleteRelationTypeResult = {
  __typename?: 'DeleteRelationTypeResult'
  /** Whether or not the relation type was deleted. */
  deleted: Scalars['Boolean']
}

/** Modifier flags that can be set of object and relation types. */
export enum Flag {
  /** Hidden types are not displayed in the Aserto Console. */
  Hidden = 'HIDDEN',
  /** Read-only types are not editable in the Aserto Console. */
  Readonly = 'READONLY',
  /** Marsk a type as internal. */
  System = 'SYSTEM',
}

/** A comparison predicate for filtering on type status fields. */
export type FlagsWhere = {
  /** The type's status contains all of the given flags. */
  contains?: InputMaybe<Array<Flag>>
  /** The type's status is equal to the given flags. */
  equals?: InputMaybe<Array<Flag>>
  /** The type's status does not contain any of the given flags. */
  notContains?: InputMaybe<Array<Flag>>
}

/**
 * A comparison predicate for filtering on numeric fields.
 *
 * At most one field may be specified.
 */
export type IntWhere = {
  /** The field being compared is equal to the given value. */
  equals?: InputMaybe<Scalars['Int']>
  /** The field being compared is greater than the given value. */
  greaterThan?: InputMaybe<Scalars['Int']>
  /** The field being compared is greater than or equal to the given value. */
  greaterThanOrEqual?: InputMaybe<Scalars['Int']>
  /** The field being compared is equal to one of the given values.. */
  in?: InputMaybe<Array<Scalars['Int']>>
  /** The field being compared is less than the given value. */
  lessThan?: InputMaybe<Scalars['Int']>
  /** The field being compared is less than or equal to the given value. */
  lessThanOrEqual?: InputMaybe<Scalars['Int']>
  /** The field being compared is not equal to the given value. */
  notEquals?: InputMaybe<Scalars['Int']>
  /** The field being compared is not equal to any of the given values. */
  notIn?: InputMaybe<Array<Scalars['Int']>>
}

/** Metadata about an entity's lifecycle. */
export type Lifecycle = {
  __typename?: 'Lifecycle'
  /** Creation time. */
  createdAt: Scalars['Time']
  /**
   * Deletion time.
   *
   * Null if the object is not deleted.
   */
  deletedAt?: Maybe<Scalars['Time']>
  /** Last update time. */
  updatedAt: Scalars['Time']
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Delete an existing object and all its relations. */
  deleteObject: DeleteObjectResult
  /** Delete an object type. */
  deleteObjectType: DeleteObjectTypeResult
  /** Delete a permission. */
  deletePermission: DeletePermissionResult
  /** Delete a relation. */
  deleteRelation: DeleteRelationResult
  /** Delete a relation type. */
  deleteRelationType: DeleteRelationTypeResult
  /** Delete a permission from a relation type. */
  deleteRelationTypePermission: DeleteRelationTypePermissionResult
  /** Create a new object or modify an existing one. */
  setObject: SetObjectResult
  /** Create a new object type or update an existing one. */
  setObjectType: SetObjectTypeResult
  /** Create or update a permission. */
  setPermission: SetPermissionResult
  /** Create a new relation. */
  setRelation: SetRelationResult
  /** Creates a new relation type or updates an existing one. */
  setRelationType: SetRelationTypeResult
  /** Create or update a relation type permission. */
  setRelationTypePermission: SetRelationTypePermissionResult
}

export type MutationDeleteObjectArgs = {
  selector: ObjectSelector
}

export type MutationDeleteObjectTypeArgs = {
  selector: ObjectTypeSelector
}

export type MutationDeletePermissionArgs = {
  selector: PermissionSelector
}

export type MutationDeleteRelationArgs = {
  relation: RelationInput
}

export type MutationDeleteRelationTypeArgs = {
  selector: RelationTypeSelector
}

export type MutationDeleteRelationTypePermissionArgs = {
  permission: PermissionSelector
  relationType: RelationTypeSelector
}

export type MutationSetObjectArgs = {
  object: SetObjectInput
}

export type MutationSetObjectTypeArgs = {
  type: SetObjectTypeInput
}

export type MutationSetPermissionArgs = {
  permission: SetPermissionInput
}

export type MutationSetRelationArgs = {
  relation: RelationInput
}

export type MutationSetRelationTypeArgs = {
  type: SetRelationTypeInput
}

export type MutationSetRelationTypePermissionArgs = {
  permission: SetRelationTypePermissionInput
}

/**
 * Objects are the primary nodes in the directory graph.
 *
 * They be associated with each other using Relations.
 */
export type Object = {
  __typename?: 'Object'
  /** Optional display name for the object. */
  displayName: Scalars['String']
  /** A hash value of object's fields. */
  hash: Scalars['Hash']
  /** Unique identifier of the object. */
  id: Scalars['ID']
  /** User-defined identifier for the object. Unique within an object type. */
  key: Scalars['String']
  /** Metadata about the object's life cycle. */
  lifecycle: Lifecycle
  /** A JSON collection of arbitrary properties associated with the object. */
  properties: Scalars['Map']
  /**
   * The relations this object has.
   *
   * side: The object's side in the relation. "SOURCE" returns relations in which the object is the source, and
   * "TARGET" returns relations in which the object is the target.
   */
  relations: RelationConnection
  /** The type of the object. */
  type: ObjectType
}

/**
 * Objects are the primary nodes in the directory graph.
 *
 * They be associated with each other using Relations.
 */
export type ObjectRelationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  first: Scalars['Int']
  options?: InputMaybe<RelationOptions>
  side: RelationSide
}

export type ObjectConnection = {
  __typename?: 'ObjectConnection'
  /** The collection of object in the page. */
  nodes: Array<Object>
  /** Pagination information about the returned page. */
  pageInfo: PageInfo
}

/** Input that uniquely identifies an object by key. */
export type ObjectKey = {
  /** The key of the object to select. */
  key: Scalars['String']
  /** The type of the object to select. */
  type?: InputMaybe<ObjectTypeSelector>
}

/**
 * Input that uniquely identifies an object.
 *
 * The fields are mutually exclusive. Exactly one value must be set.
 */
export type ObjectSelector = {
  /** The ID of the object to select. */
  id?: InputMaybe<Scalars['ID']>
  /** The key and type of the object to select. */
  key?: InputMaybe<ObjectKey>
}

/** ObjectType represents the kinds of objects that can exist in the directory. */
export type ObjectType = {
  __typename?: 'ObjectType'
  /** Friendly name of the object type. */
  displayName: Scalars['String']
  /** Unique identifier for the object type. */
  id: Scalars['TypeID']
  /** Whether the object type represents a subject type. */
  isSubject: Scalars['Boolean']
  /** Metadata about the object type's lifecycle. */
  lifecycle: Lifecycle
  /** Name of the object type. */
  name: Scalars['String']
  /** Sort order of the object type. */
  ordinal: Scalars['Int']
  /** The types of relations that can be created with objects of this type. */
  relationTypes: RelationTypeConnection
  /** A of flags that describe the status of the object type. */
  status: Array<Flag>
}

/** ObjectType represents the kinds of objects that can exist in the directory. */
export type ObjectTypeRelationTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  first: Scalars['Int']
}

/** A paginated collection of object types. */
export type ObjectTypeConnection = {
  __typename?: 'ObjectTypeConnection'
  /** The collection of object types in the page. */
  nodes: Array<ObjectType>
  /** Pagination information about the returned page. */
  pageInfo: PageInfo
}

/**
 * Input that uniquely identifies a object type.
 *
 * The fields are mutually exclusive. Exactly one value must be set.
 */
export type ObjectTypeSelector = {
  /** The selected object type's ID. */
  id?: InputMaybe<Scalars['TypeID']>
  /** The selected object type's name. */
  name?: InputMaybe<Scalars['String']>
}

/**
 * A comparison predicate for filtering object types.
 *
 * All fields are optional. Only object types that match all given fields are included.
 */
export type ObjectTypesWhere = {
  /** Filter types by display name. */
  displayName?: InputMaybe<StringWhere>
  /** Filter object types by id. */
  id?: InputMaybe<TypeIdWhere>
  /** If true, only include subject types. If false, only include non-subject types. */
  isSubject?: InputMaybe<Scalars['Boolean']>
  /** Filter types by name. */
  name?: InputMaybe<StringWhere>
  /** Filter types by sort order. */
  ordinal?: InputMaybe<IntWhere>
  /** Filter types by their status flags. */
  status?: InputMaybe<FlagsWhere>
}

/** A comparison predicate for filtering objects. */
export type ObjectsWhere = {
  /** Filter relations by display name. */
  displayName?: InputMaybe<StringWhere>
  /** Filter relations by key. */
  key?: InputMaybe<StringWhere>
  /** Filter objects by their type. */
  type?: InputMaybe<ObjectTypesWhere>
}

/** PageInfo holds information about a returned page of results. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /**
   * The cursor to use to fetch the next page. If there are no more pages, this field is null.
   *
   * The value of this field is a cursor that can be used with the `after` parameter to fetch the next page of results.
   */
  endCursor?: Maybe<Scalars['Cursor']>
  /** True if there are more pages to fetch, false otherwise. */
  hasNextPage: Scalars['Boolean']
}

/** An action that can be performed on a resource. */
export type Permission = {
  __typename?: 'Permission'
  /** An optional name for the permission */
  displayName?: Maybe<Scalars['String']>
  /** Unique identifier for the permission. */
  id: Scalars['ID']
  /** Metadata about the permission's lifecycle. */
  lifecycle: Lifecycle
  /** Unique user-defined value representing the permission. */
  permission: Scalars['String']
  /** The relation types that include this permission. */
  relationTypes: RelationTypeConnection
}

/** An action that can be performed on a resource. */
export type PermissionRelationTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  first: Scalars['Int']
}

/** A paginated collection of Permission instances. */
export type PermissionConnection = {
  __typename?: 'PermissionConnection'
  /** The collection of permissions in the page. */
  nodes: Array<Permission>
  /** Pagination information about the returned page. */
  pageInfo: PageInfo
}

/**
 * Input that uniquely identifies a permission.
 *
 * The fields are mutually exclusive. Exactly one value must be set.
 */
export type PermissionSelector = {
  /** The permission ID. */
  id?: InputMaybe<Scalars['ID']>
  /** The unique permission value. */
  permission?: InputMaybe<Scalars['String']>
}

export type Query = {
  __typename?: 'Query'
  /** Select a single object. */
  object: Object
  /** Select a single object type. */
  objectType: ObjectType
  /** Find object types that match the given criteria. */
  objectTypes: ObjectTypeConnection
  /** Find objects that match the given criteria. */
  objects: ObjectConnection
  /** Select a single permission. */
  permission: Permission
  /** Enumerate permissions. */
  permissions: PermissionConnection
  /** Select a single relation type. */
  relationType: RelationType
  /** Find relation types that match the given criteria. */
  relationTypes: RelationTypeConnection
  /** Find relations that match the given criteria. */
  relations: RelationConnection
}

export type QueryObjectArgs = {
  selector: ObjectSelector
}

export type QueryObjectTypeArgs = {
  selector: ObjectTypeSelector
}

export type QueryObjectTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  first: Scalars['Int']
  where?: InputMaybe<ObjectTypesWhere>
}

export type QueryObjectsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  first: Scalars['Int']
  where?: InputMaybe<ObjectsWhere>
}

export type QueryPermissionArgs = {
  selector: PermissionSelector
}

export type QueryPermissionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  first: Scalars['Int']
}

export type QueryRelationTypeArgs = {
  selector: RelationTypeSelector
}

export type QueryRelationTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  first: Scalars['Int']
  where?: InputMaybe<RelationTypesWhere>
}

export type QueryRelationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  first: Scalars['Int']
  where?: InputMaybe<RelationsWhere>
}

/** A relation between a source object and a target object. */
export type Relation = {
  __typename?: 'Relation'
  /** Metadata about the relation type's lifecycle. */
  lifecycle: Lifecycle
  /** The source of the relation. */
  source: Object
  /** The target of the relation. */
  target: Object
  /** The type of relation. */
  type: RelationType
}

/** A paginated collection of relations. */
export type RelationConnection = {
  __typename?: 'RelationConnection'
  /** The collection of relations in the page. */
  nodes: Array<Relation>
  /** Pagination information about the returned page. */
  pageInfo: PageInfo
}

/** Input to the setRelation mutation. */
export type RelationInput = {
  /** The relation type. */
  relationType: RelationTypeSelector
  /** The source object. */
  source: ObjectSelector
  /** The target object. */
  target: ObjectSelector
}

/** Options for queriying object relations. */
export type RelationOptions = {
  /** [optional] Only include relations with the specified object ID. */
  other?: InputMaybe<ObjectSelector>
  /** [optional] Only include relations with objects of the specified object type. */
  otherType?: InputMaybe<ObjectTypeSelector>
  /** [optional] Only include relations of this type. */
  relationType?: InputMaybe<RelationTypeSelector>
}

/** The side of a relation in which an object may be found. */
export enum RelationSide {
  /** Object must be on the source side of the relation. */
  Source = 'SOURCE',
  /** Object must be on the target side of the relation. */
  Target = 'TARGET',
}

/** A kind of relation that can exist between subjects and resources. */
export type RelationType = {
  __typename?: 'RelationType'
  /** Display name for the relation type. */
  displayName: Scalars['String']
  /** Unique identifier of the relation type. */
  id: Scalars['TypeID']
  /** Metadata about the relation type's lifecycle. */
  lifecycle: Lifecycle
  /** Unique name for the relation type. Unique for the object type. */
  name: Scalars['String']
  /** The type of object that can be part of the relation. */
  objectType: ObjectType
  /** Sort order of the relation type. */
  ordinal: Scalars['Int']
  /** The permissions that the relation grants to subjects. */
  permissions: PermissionConnection
  /** A set of flags that describe the status of the object type. */
  status: Array<Flag>
  /**
   * A list of relation types that are supertypes of this relation type.
   *
   * This relation type receives all permissions defined in the supertypes.
   * Unioned relation types must be defined with the same object type.
   */
  unions: Array<RelationType>
}

/** A kind of relation that can exist between subjects and resources. */
export type RelationTypePermissionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>
  first: Scalars['Int']
}

/** A paginated collection of relation types. */
export type RelationTypeConnection = {
  __typename?: 'RelationTypeConnection'
  /** The collection of relation types in the page. */
  nodes: Array<RelationType>
  /** Pagination information about the returned page. */
  pageInfo: PageInfo
}

/**
 * A RelationTypeName identifies a relation type by the combination of its name and associated object type.
 *
 * Both fields are required.
 */
export type RelationTypeName = {
  /** Name of the relation type. */
  name: Scalars['String']
  /** The type of object that can be part of the relation. */
  objectType: ObjectTypeSelector
}

/** An association between a relation type and a permission. */
export type RelationTypePermission = {
  __typename?: 'RelationTypePermission'
  /** Whether or not the permission is granted or denied. */
  allowed: Scalars['Boolean']
  /** Metadata about the lifecycle of the relation type permission. */
  lifecycle: Lifecycle
  /** The permission. */
  permission: Permission
  /** The relation type. */
  relationType: RelationType
}

/** Paginated collection of RelationTypePermission instances. */
export type RelationTypePermissionConnection = {
  __typename?: 'RelationTypePermissionConnection'
  /** The collection of RelationTypePermission instances in the page. */
  nodes: Array<RelationTypePermission>
  /** Pagination information about the returned page. */
  pageInfo: PageInfo
}

/**
 * A RelationTypeSelector identifies a relation type by id or the combination of its name and associated object type.
 *
 * The two fields are mutually exclusive. Only one may be specified.
 */
export type RelationTypeSelector = {
  /** Select relation type by ID. */
  id?: InputMaybe<Scalars['TypeID']>
  /** Select relation type by name + object-type. */
  name?: InputMaybe<RelationTypeName>
}

/**
 * A comparison predicate for filtering relation types.
 *
 * All fields are optional. Only relation types that match all given fields are included.
 */
export type RelationTypesWhere = {
  /** Filter relation types by display name. */
  displayName?: InputMaybe<StringWhere>
  /** Filter relation types by id. */
  id?: InputMaybe<TypeIdWhere>
  /** Filter relation types by name. */
  name?: InputMaybe<StringWhere>
  /** Filter relation types by their associated object type. */
  objectType?: InputMaybe<ObjectTypesWhere>
  /** Filter relation types by sort order. */
  ordinal?: InputMaybe<IntWhere>
  /** Filter relation types by their status flags. */
  status?: InputMaybe<FlagsWhere>
  /** Filter relation types by the relation types they are a union of. */
  unions?: InputMaybe<UnionsWhere>
}

/** A comparison predicate for filtering relations. */
export type RelationsWhere = {
  /** Filter relations by their source object. */
  source?: InputMaybe<ObjectsWhere>
  /** Filter relations by their target object. */
  target?: InputMaybe<ObjectsWhere>
  /** Filter relations by their type. */
  type?: InputMaybe<RelationTypesWhere>
}

/**
 * Input for the setObject mutation.
 *
 * If `id` is set, the mutation updates an existing object. In this case `type` is optional and if specfied, must match
 * the type of the existing object.
 *
 * If `id` is not set then `type` and `key` are required. If an object with the same type and key already exists,
 * it is updated, otherwise a new object is created.
 */
export type SetObjectInput = {
  /** Optional display name for a new object or new name for an existing object. */
  displayName: Scalars['String']
  /**
   * Optional value of the hash value previously read from the directory.
   *
   * If a hash value is specified and the object being mutated already exists, the update will be rejected unless the hash
   * value matches the existing object's hash value.
   * This can be used to ensure that the object being mutated has not been modified since it was read from the directory.
   */
  hash?: InputMaybe<Scalars['Hash']>
  /**
   * ID of an existing object to update. If set, an object with the specified ID must already exist and the mutation
   * updates its attributes.
   */
  id?: InputMaybe<Scalars['ID']>
  /**
   * The key of the object to create or update. If `id` is set, `key` is optional and, if specified, replaces the
   * object' existing key.
   */
  key: Scalars['String']
  /**
   * Optional set of JSON properties to be set on the object. If the object already exists, its properties are replaced
   * by the specified values.
   */
  properties: Scalars['Map']
  /** The type of object to create or update. If `id` is set, `type` must match the type of the existing object. */
  type: ObjectTypeSelector
}

/** Result of the setObject mutation. */
export type SetObjectResult = {
  __typename?: 'SetObjectResult'
  /** The object that was created or updated. */
  object: Object
}

/**
 * Input for the setObjectType mutation. This input type is used to create or modify an object type.
 *
 * All fields are required except for `id`. If an object type with the specified `name` already exists, it is updated.
 * If no object type with the specified name exists, a new object type is created.
 *
 * The only time `id` is required is to change the name of an existing object type. In that case, `id` is used to identify
 * the object type and `name` is the new name.
 */
export type SetObjectTypeInput = {
  /** Friendly display name of the object type. This is how the object type appears in the Aserto console. */
  displayName: Scalars['String']
  /** ID of an existing object type to update. If set, an object type with the specified ID must already exist. */
  id?: InputMaybe<Scalars['TypeID']>
  /** Whether or not the object type represents a subject (user, group, etc.). */
  isSubject: Scalars['Boolean']
  /** Name of the object type. Must be unique among all object types in the directory. */
  name: Scalars['String']
  /** Sort order of the object type. Lower ordinals appear first when enumerating object types. */
  ordinal: Scalars['Int']
  /** A set of modifier flags that can be applied to the object type. It should be left empty (`[]`) in most cases. */
  status: Array<Flag>
}

/** Result of the setObjectType mutation. */
export type SetObjectTypeResult = {
  __typename?: 'SetObjectTypeResult'
  /** The object type that was created or updated. */
  objectType: ObjectType
}

/** Input to the setPermission mutation. */
export type SetPermissionInput = {
  /** Friendly name for the permission. */
  displayName?: InputMaybe<Scalars['String']>
  /** The ID of an existing permission to update. If provided, a permission with the specified ID must exist. */
  id?: InputMaybe<Scalars['ID']>
  /** The unique permission value. */
  permission?: InputMaybe<Scalars['String']>
}

export type SetPermissionResult = {
  __typename?: 'SetPermissionResult'
  /** The created permission. */
  permission: Permission
}

/** Result of the setRelation mutation. */
export type SetRelationResult = {
  __typename?: 'SetRelationResult'
  /** The created relation. */
  relation: Relation
}

/** Input for the setRelationType mutation. */
export type SetRelationTypeInput = {
  /** Display name of the relation type. */
  displayName?: InputMaybe<Scalars['String']>
  /** ID of an existing relation type to update. If set, a relation type with the specified ID must already exist. */
  id?: InputMaybe<Scalars['TypeID']>
  /** Name of the relation type. */
  name?: InputMaybe<Scalars['String']>
  /**
   * The type of object that can be the source of the relation. If `id` is set the `objectType` is optional and if
   * specified, must match the object type defined on the existing relation type.
   */
  objectType?: InputMaybe<ObjectTypeSelector>
  /** Sort ordinal of the relation type. */
  ordinal?: InputMaybe<Scalars['Int']>
  /** A set of flags that describe the status of the relation type. */
  status?: InputMaybe<Array<Flag>>
  /**
   * A list of relation types that are supertypes of this relation type.
   *
   * This relation type receives all permissions defined in the supertypes.
   * Unioned relation types must be defined with the same object type.
   */
  unions?: InputMaybe<Array<RelationTypeSelector>>
}

/** Input to the setRelationTypePermission mutation. */
export type SetRelationTypePermissionInput = {
  /** Whether or not the permission is granted or denied. */
  allowed: Scalars['Boolean']
  /** The permission being set. */
  permission: PermissionSelector
  /** The relation type to associate with. */
  relationType: RelationTypeSelector
}

export type SetRelationTypePermissionResult = {
  __typename?: 'SetRelationTypePermissionResult'
  /** Whether or not the permission is granted or denied. */
  allowed: Scalars['Boolean']
  /** The permission associated with the relation type. */
  permission: Permission
  /** The relation type with the updated permission. */
  relationType: RelationType
}

/** Result of the setRelationType mutation. */
export type SetRelationTypeResult = {
  __typename?: 'SetRelationTypeResult'
  /** The created relation type. */
  relationType: RelationType
}

/**
 * A comparison predicate for filtering on string fields.
 *
 * At most one field may be specified.
 */
export type StringWhere = {
  /** The field being compared contains the given value as a substring. */
  contains?: InputMaybe<Scalars['String']>
  /** The field being compared contains the given value as a suffix. */
  endsWith?: InputMaybe<Scalars['String']>
  /** The field being compared is equal to the given value. */
  equals?: InputMaybe<Scalars['String']>
  /** The field being compared is equal to one of the given values.. */
  in?: InputMaybe<Array<Scalars['String']>>
  /** The field being compared is not equal to the given value. */
  notEquals?: InputMaybe<Scalars['String']>
  /** The field being compared is not equal to any of the given values. */
  notIn?: InputMaybe<Array<Scalars['String']>>
  /** The field being compared contains the given value as a prefix. */
  startsWith?: InputMaybe<Scalars['String']>
}

/** A comparison predicate for filtering types on their id field. */
export type TypeIdWhere = {
  /** The type's id is equal to the given id. */
  equals?: InputMaybe<Scalars['TypeID']>
  /** The type's id is equal to one of the given ids. */
  in?: InputMaybe<Array<Scalars['TypeID']>>
  /** The type's id is not equal to the given id. */
  notEquals?: InputMaybe<Scalars['TypeID']>
  /** The type's id is not equal to any of the given ids. */
  notIn?: InputMaybe<Array<Scalars['TypeID']>>
}

/** A comparison predicate for filtering relation type unions. */
export type UnionsWhere = {
  /** Matches relation types that include all given relation types in their `unions` field. */
  contains?: InputMaybe<Array<InputMaybe<RelationTypeSelector>>>
  /** Matches relation types that do not include any of the given relation types in their `unions` field. */
  notContains?: InputMaybe<Array<InputMaybe<RelationTypeSelector>>>
}

export type SettableObjectFragment = {
  __typename?: 'Object'
  displayName: string
  id: string
  key: string
  properties: unknown
  type: { __typename?: 'ObjectType'; name: string }
}

export type DisplayObjectTypeFragment = {
  __typename?: 'ObjectType'
  status: Array<Flag>
  displayName: string
  name: string
  id: unknown
  isSubject: boolean
}

export type ListObjectTypesQueryVariables = Exact<{
  first: Scalars['Int']
  after?: InputMaybe<Scalars['Cursor']>
}>

export type ListObjectTypesQuery = {
  __typename?: 'Query'
  objectTypes: {
    __typename?: 'ObjectTypeConnection'
    nodes: Array<{
      __typename?: 'ObjectType'
      status: Array<Flag>
      displayName: string
      name: string
      id: unknown
      isSubject: boolean
    }>
  }
}

export type GetObjectTypeByNameQueryVariables = Exact<{
  typeName: Scalars['String']
}>

export type GetObjectTypeByNameQuery = {
  __typename?: 'Query'
  objectType: {
    __typename?: 'ObjectType'
    status: Array<Flag>
    displayName: string
    name: string
    id: unknown
    isSubject: boolean
  }
}

export type ListObjectsByTypeQueryVariables = Exact<{
  typeName: Scalars['String']
  first: Scalars['Int']
  after?: InputMaybe<Scalars['Cursor']>
  displayNamePart?: InputMaybe<Scalars['String']>
}>

export type ListObjectsByTypeQuery = {
  __typename?: 'Query'
  objects: {
    __typename?: 'ObjectConnection'
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: unknown | null }
    nodes: Array<{
      __typename?: 'Object'
      displayName: string
      id: string
      key: string
      properties: unknown
      type: { __typename?: 'ObjectType'; name: string }
    }>
  }
}

export type GetObjectByTypeKeyQueryVariables = Exact<{
  typeName: Scalars['String']
  key: Scalars['String']
}>

export type GetObjectByTypeKeyQuery = {
  __typename?: 'Query'
  object: {
    __typename?: 'Object'
    displayName: string
    id: string
    key: string
    properties: unknown
    type: { __typename?: 'ObjectType'; name: string }
  }
}

export type GetObjectByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetObjectByIdQuery = {
  __typename?: 'Query'
  object: {
    __typename?: 'Object'
    displayName: string
    id: string
    key: string
    properties: unknown
    type: { __typename?: 'ObjectType'; name: string }
  }
}

export type GetAccountForUserIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetAccountForUserIdQuery = {
  __typename?: 'Query'
  object: {
    __typename?: 'Object'
    relations: {
      __typename?: 'RelationConnection'
      nodes: Array<{
        __typename?: 'Relation'
        source: {
          __typename?: 'Object'
          displayName: string
          id: string
          key: string
          properties: unknown
          type: { __typename?: 'ObjectType'; name: string }
        }
      }>
    }
  }
}

export type GetObjectRelationsByIdAndSideQueryVariables = Exact<{
  id: Scalars['ID']
  side: RelationSide
  first: Scalars['Int']
  after?: InputMaybe<Scalars['Cursor']>
}>

export type GetObjectRelationsByIdAndSideQuery = {
  __typename?: 'Query'
  object: {
    __typename?: 'Object'
    relations: {
      __typename?: 'RelationConnection'
      pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: unknown | null }
      nodes: Array<{
        __typename?: 'Relation'
        target: {
          __typename?: 'Object'
          id: string
          key: string
          displayName: string
          type: { __typename?: 'ObjectType'; isSubject: boolean; name: string }
        }
        source: {
          __typename?: 'Object'
          id: string
          key: string
          displayName: string
          type: { __typename?: 'ObjectType'; isSubject: boolean; name: string }
        }
        type: { __typename?: 'RelationType'; id: unknown; name: string }
      }>
    }
  }
}

export type ListRelationTypesQueryVariables = Exact<{
  first: Scalars['Int']
  after?: InputMaybe<Scalars['Cursor']>
}>

export type ListRelationTypesQuery = {
  __typename?: 'Query'
  relationTypes: {
    __typename?: 'RelationTypeConnection'
    nodes: Array<{
      __typename?: 'RelationType'
      status: Array<Flag>
      displayName: string
      id: unknown
      name: string
      objectType: {
        __typename?: 'ObjectType'
        displayName: string
        name: string
        status: Array<Flag>
      }
    }>
  }
}

export type ListPermissionsQueryVariables = Exact<{
  first_permissions: Scalars['Int']
  first_relationTypes: Scalars['Int']
  after_permissions?: InputMaybe<Scalars['Cursor']>
  after_relationTypes?: InputMaybe<Scalars['Cursor']>
}>

export type ListPermissionsQuery = {
  __typename?: 'Query'
  permissions: {
    __typename?: 'PermissionConnection'
    nodes: Array<{
      __typename?: 'Permission'
      displayName?: string | null
      relationTypes: {
        __typename?: 'RelationTypeConnection'
        nodes: Array<{
          __typename?: 'RelationType'
          displayName: string
          objectType: { __typename?: 'ObjectType'; name: string }
        }>
      }
    }>
  }
}

export type AddRelationInstanceMutationVariables = Exact<{
  sourceId: Scalars['ID']
  name: Scalars['String']
  objectTypeName?: InputMaybe<Scalars['String']>
  targetId: Scalars['ID']
}>

export type AddRelationInstanceMutation = {
  __typename?: 'Mutation'
  setRelation: {
    __typename?: 'SetRelationResult'
    relation: {
      __typename?: 'Relation'
      type: { __typename?: 'RelationType'; id: unknown; name: string }
    }
  }
}

export type RemoveRelationMutationVariables = Exact<{
  sourceId: Scalars['ID']
  targetId: Scalars['ID']
  relationTypeId: Scalars['TypeID']
}>

export type RemoveRelationMutation = {
  __typename?: 'Mutation'
  deleteRelation: { __typename?: 'DeleteRelationResult'; deleted: boolean }
}

export type CreateObjectMutationVariables = Exact<{
  objectTypeName: Scalars['String']
  key: Scalars['String']
  displayName: Scalars['String']
  properties?: Scalars['Map']
}>

export type CreateObjectMutation = {
  __typename?: 'Mutation'
  setObject: { __typename?: 'SetObjectResult'; object: { __typename?: 'Object'; id: string } }
}

export type UpdateObjectMutationVariables = Exact<{
  object: SetObjectInput
}>

export type UpdateObjectMutation = {
  __typename?: 'Mutation'
  setObject: { __typename?: 'SetObjectResult'; object: { __typename?: 'Object'; id: string } }
}

export const SettableObjectFragmentDoc = `
    fragment SettableObject on Object {
  type {
    name
  }
  displayName
  id
  key
  properties
}
    `
export const DisplayObjectTypeFragmentDoc = `
    fragment DisplayObjectType on ObjectType {
  status
  displayName
  name
  id
  isSubject
}
    `
export const ListObjectTypesDocument = `
    query ListObjectTypes($first: Int!, $after: Cursor) {
  objectTypes(first: $first, after: $after) {
    nodes {
      ...DisplayObjectType
    }
  }
}
    ${DisplayObjectTypeFragmentDoc}`
export const useListObjectTypesQuery = <TData = ListObjectTypesQuery, TError = unknown>(
  variables: ListObjectTypesQueryVariables,
  options?: UseQueryOptions<ListObjectTypesQuery, TError, TData>
) =>
  useQuery<ListObjectTypesQuery, TError, TData>(
    ['ListObjectTypes', variables],
    useFetchFromDs0<ListObjectTypesQuery, ListObjectTypesQueryVariables>(
      ListObjectTypesDocument
    ).bind(null, variables),
    options
  )

useListObjectTypesQuery.getKey = (variables: ListObjectTypesQueryVariables) => [
  'ListObjectTypes',
  variables,
]
export const useInfiniteListObjectTypesQuery = <TData = ListObjectTypesQuery, TError = unknown>(
  variables: ListObjectTypesQueryVariables,
  options?: UseInfiniteQueryOptions<ListObjectTypesQuery, TError, TData>
) => {
  const query =
    useFetchFromDs0<ListObjectTypesQuery, ListObjectTypesQueryVariables>(ListObjectTypesDocument)
  return useInfiniteQuery<ListObjectTypesQuery, TError, TData>(
    ['ListObjectTypes.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  )
}

useInfiniteListObjectTypesQuery.getKey = (variables: ListObjectTypesQueryVariables) => [
  'ListObjectTypes.infinite',
  variables,
]
export const GetObjectTypeByNameDocument = `
    query GetObjectTypeByName($typeName: String!) {
  objectType(selector: {name: $typeName}) {
    ...DisplayObjectType
  }
}
    ${DisplayObjectTypeFragmentDoc}`
export const useGetObjectTypeByNameQuery = <TData = GetObjectTypeByNameQuery, TError = unknown>(
  variables: GetObjectTypeByNameQueryVariables,
  options?: UseQueryOptions<GetObjectTypeByNameQuery, TError, TData>
) =>
  useQuery<GetObjectTypeByNameQuery, TError, TData>(
    ['GetObjectTypeByName', variables],
    useFetchFromDs0<GetObjectTypeByNameQuery, GetObjectTypeByNameQueryVariables>(
      GetObjectTypeByNameDocument
    ).bind(null, variables),
    options
  )

useGetObjectTypeByNameQuery.getKey = (variables: GetObjectTypeByNameQueryVariables) => [
  'GetObjectTypeByName',
  variables,
]
export const useInfiniteGetObjectTypeByNameQuery = <
  TData = GetObjectTypeByNameQuery,
  TError = unknown
>(
  variables: GetObjectTypeByNameQueryVariables,
  options?: UseInfiniteQueryOptions<GetObjectTypeByNameQuery, TError, TData>
) => {
  const query = useFetchFromDs0<GetObjectTypeByNameQuery, GetObjectTypeByNameQueryVariables>(
    GetObjectTypeByNameDocument
  )
  return useInfiniteQuery<GetObjectTypeByNameQuery, TError, TData>(
    ['GetObjectTypeByName.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  )
}

useInfiniteGetObjectTypeByNameQuery.getKey = (variables: GetObjectTypeByNameQueryVariables) => [
  'GetObjectTypeByName.infinite',
  variables,
]
export const ListObjectsByTypeDocument = `
    query ListObjectsByType($typeName: String!, $first: Int!, $after: Cursor, $displayNamePart: String = null) {
  objects(
    after: $after
    first: $first
    where: {type: {name: {equals: $typeName}}, displayName: {contains: $displayNamePart}}
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    nodes {
      ...SettableObject
    }
  }
}
    ${SettableObjectFragmentDoc}`
export const useListObjectsByTypeQuery = <TData = ListObjectsByTypeQuery, TError = unknown>(
  variables: ListObjectsByTypeQueryVariables,
  options?: UseQueryOptions<ListObjectsByTypeQuery, TError, TData>
) =>
  useQuery<ListObjectsByTypeQuery, TError, TData>(
    ['ListObjectsByType', variables],
    useFetchFromDs0<ListObjectsByTypeQuery, ListObjectsByTypeQueryVariables>(
      ListObjectsByTypeDocument
    ).bind(null, variables),
    options
  )

useListObjectsByTypeQuery.getKey = (variables: ListObjectsByTypeQueryVariables) => [
  'ListObjectsByType',
  variables,
]
export const useInfiniteListObjectsByTypeQuery = <TData = ListObjectsByTypeQuery, TError = unknown>(
  variables: ListObjectsByTypeQueryVariables,
  options?: UseInfiniteQueryOptions<ListObjectsByTypeQuery, TError, TData>
) => {
  const query =
    useFetchFromDs0<ListObjectsByTypeQuery, ListObjectsByTypeQueryVariables>(
      ListObjectsByTypeDocument
    )
  return useInfiniteQuery<ListObjectsByTypeQuery, TError, TData>(
    ['ListObjectsByType.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  )
}

useInfiniteListObjectsByTypeQuery.getKey = (variables: ListObjectsByTypeQueryVariables) => [
  'ListObjectsByType.infinite',
  variables,
]
export const GetObjectByTypeKeyDocument = `
    query GetObjectByTypeKey($typeName: String!, $key: String!) {
  object(selector: {key: {type: {name: $typeName}, key: $key}}) {
    ...SettableObject
  }
}
    ${SettableObjectFragmentDoc}`
export const useGetObjectByTypeKeyQuery = <TData = GetObjectByTypeKeyQuery, TError = unknown>(
  variables: GetObjectByTypeKeyQueryVariables,
  options?: UseQueryOptions<GetObjectByTypeKeyQuery, TError, TData>
) =>
  useQuery<GetObjectByTypeKeyQuery, TError, TData>(
    ['GetObjectByTypeKey', variables],
    useFetchFromDs0<GetObjectByTypeKeyQuery, GetObjectByTypeKeyQueryVariables>(
      GetObjectByTypeKeyDocument
    ).bind(null, variables),
    options
  )

useGetObjectByTypeKeyQuery.getKey = (variables: GetObjectByTypeKeyQueryVariables) => [
  'GetObjectByTypeKey',
  variables,
]
export const useInfiniteGetObjectByTypeKeyQuery = <
  TData = GetObjectByTypeKeyQuery,
  TError = unknown
>(
  variables: GetObjectByTypeKeyQueryVariables,
  options?: UseInfiniteQueryOptions<GetObjectByTypeKeyQuery, TError, TData>
) => {
  const query = useFetchFromDs0<GetObjectByTypeKeyQuery, GetObjectByTypeKeyQueryVariables>(
    GetObjectByTypeKeyDocument
  )
  return useInfiniteQuery<GetObjectByTypeKeyQuery, TError, TData>(
    ['GetObjectByTypeKey.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  )
}

useInfiniteGetObjectByTypeKeyQuery.getKey = (variables: GetObjectByTypeKeyQueryVariables) => [
  'GetObjectByTypeKey.infinite',
  variables,
]
export const GetObjectByIdDocument = `
    query GetObjectById($id: ID!) {
  object(selector: {id: $id}) {
    ...SettableObject
  }
}
    ${SettableObjectFragmentDoc}`
export const useGetObjectByIdQuery = <TData = GetObjectByIdQuery, TError = unknown>(
  variables: GetObjectByIdQueryVariables,
  options?: UseQueryOptions<GetObjectByIdQuery, TError, TData>
) =>
  useQuery<GetObjectByIdQuery, TError, TData>(
    ['GetObjectById', variables],
    useFetchFromDs0<GetObjectByIdQuery, GetObjectByIdQueryVariables>(GetObjectByIdDocument).bind(
      null,
      variables
    ),
    options
  )

useGetObjectByIdQuery.getKey = (variables: GetObjectByIdQueryVariables) => [
  'GetObjectById',
  variables,
]
export const useInfiniteGetObjectByIdQuery = <TData = GetObjectByIdQuery, TError = unknown>(
  variables: GetObjectByIdQueryVariables,
  options?: UseInfiniteQueryOptions<GetObjectByIdQuery, TError, TData>
) => {
  const query =
    useFetchFromDs0<GetObjectByIdQuery, GetObjectByIdQueryVariables>(GetObjectByIdDocument)
  return useInfiniteQuery<GetObjectByIdQuery, TError, TData>(
    ['GetObjectById.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  )
}

useInfiniteGetObjectByIdQuery.getKey = (variables: GetObjectByIdQueryVariables) => [
  'GetObjectById.infinite',
  variables,
]
export const GetAccountForUserIdDocument = `
    query GetAccountForUserId($id: ID!) {
  object(selector: {id: $id}) {
    relations(
      side: TARGET
      options: {relationType: {name: {name: "account", objectType: {name: "tenant"}}}}
      first: 1
    ) {
      nodes {
        source {
          ...SettableObject
        }
      }
    }
  }
}
    ${SettableObjectFragmentDoc}`
export const useGetAccountForUserIdQuery = <TData = GetAccountForUserIdQuery, TError = unknown>(
  variables: GetAccountForUserIdQueryVariables,
  options?: UseQueryOptions<GetAccountForUserIdQuery, TError, TData>
) =>
  useQuery<GetAccountForUserIdQuery, TError, TData>(
    ['GetAccountForUserId', variables],
    useFetchFromDs0<GetAccountForUserIdQuery, GetAccountForUserIdQueryVariables>(
      GetAccountForUserIdDocument
    ).bind(null, variables),
    options
  )

useGetAccountForUserIdQuery.getKey = (variables: GetAccountForUserIdQueryVariables) => [
  'GetAccountForUserId',
  variables,
]
export const useInfiniteGetAccountForUserIdQuery = <
  TData = GetAccountForUserIdQuery,
  TError = unknown
>(
  variables: GetAccountForUserIdQueryVariables,
  options?: UseInfiniteQueryOptions<GetAccountForUserIdQuery, TError, TData>
) => {
  const query = useFetchFromDs0<GetAccountForUserIdQuery, GetAccountForUserIdQueryVariables>(
    GetAccountForUserIdDocument
  )
  return useInfiniteQuery<GetAccountForUserIdQuery, TError, TData>(
    ['GetAccountForUserId.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  )
}

useInfiniteGetAccountForUserIdQuery.getKey = (variables: GetAccountForUserIdQueryVariables) => [
  'GetAccountForUserId.infinite',
  variables,
]
export const GetObjectRelationsByIdAndSideDocument = `
    query GetObjectRelationsByIdAndSide($id: ID!, $side: RelationSide!, $first: Int!, $after: Cursor) {
  object(selector: {id: $id}) {
    relations(side: $side, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        target {
          id
          key
          displayName
          type {
            isSubject
            name
          }
        }
        source {
          id
          key
          displayName
          type {
            isSubject
            name
          }
        }
        type {
          id
          name
        }
      }
    }
  }
}
    `
export const useGetObjectRelationsByIdAndSideQuery = <
  TData = GetObjectRelationsByIdAndSideQuery,
  TError = unknown
>(
  variables: GetObjectRelationsByIdAndSideQueryVariables,
  options?: UseQueryOptions<GetObjectRelationsByIdAndSideQuery, TError, TData>
) =>
  useQuery<GetObjectRelationsByIdAndSideQuery, TError, TData>(
    ['GetObjectRelationsByIdAndSide', variables],
    useFetchFromDs0<
      GetObjectRelationsByIdAndSideQuery,
      GetObjectRelationsByIdAndSideQueryVariables
    >(GetObjectRelationsByIdAndSideDocument).bind(null, variables),
    options
  )

useGetObjectRelationsByIdAndSideQuery.getKey = (
  variables: GetObjectRelationsByIdAndSideQueryVariables
) => ['GetObjectRelationsByIdAndSide', variables]
export const useInfiniteGetObjectRelationsByIdAndSideQuery = <
  TData = GetObjectRelationsByIdAndSideQuery,
  TError = unknown
>(
  variables: GetObjectRelationsByIdAndSideQueryVariables,
  options?: UseInfiniteQueryOptions<GetObjectRelationsByIdAndSideQuery, TError, TData>
) => {
  const query = useFetchFromDs0<
    GetObjectRelationsByIdAndSideQuery,
    GetObjectRelationsByIdAndSideQueryVariables
  >(GetObjectRelationsByIdAndSideDocument)
  return useInfiniteQuery<GetObjectRelationsByIdAndSideQuery, TError, TData>(
    ['GetObjectRelationsByIdAndSide.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  )
}

useInfiniteGetObjectRelationsByIdAndSideQuery.getKey = (
  variables: GetObjectRelationsByIdAndSideQueryVariables
) => ['GetObjectRelationsByIdAndSide.infinite', variables]
export const ListRelationTypesDocument = `
    query ListRelationTypes($first: Int!, $after: Cursor) {
  relationTypes(first: $first, after: $after) {
    nodes {
      status
      displayName
      id
      name
      objectType {
        displayName
        name
        status
      }
    }
  }
}
    `
export const useListRelationTypesQuery = <TData = ListRelationTypesQuery, TError = unknown>(
  variables: ListRelationTypesQueryVariables,
  options?: UseQueryOptions<ListRelationTypesQuery, TError, TData>
) =>
  useQuery<ListRelationTypesQuery, TError, TData>(
    ['ListRelationTypes', variables],
    useFetchFromDs0<ListRelationTypesQuery, ListRelationTypesQueryVariables>(
      ListRelationTypesDocument
    ).bind(null, variables),
    options
  )

useListRelationTypesQuery.getKey = (variables: ListRelationTypesQueryVariables) => [
  'ListRelationTypes',
  variables,
]
export const useInfiniteListRelationTypesQuery = <TData = ListRelationTypesQuery, TError = unknown>(
  variables: ListRelationTypesQueryVariables,
  options?: UseInfiniteQueryOptions<ListRelationTypesQuery, TError, TData>
) => {
  const query =
    useFetchFromDs0<ListRelationTypesQuery, ListRelationTypesQueryVariables>(
      ListRelationTypesDocument
    )
  return useInfiniteQuery<ListRelationTypesQuery, TError, TData>(
    ['ListRelationTypes.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  )
}

useInfiniteListRelationTypesQuery.getKey = (variables: ListRelationTypesQueryVariables) => [
  'ListRelationTypes.infinite',
  variables,
]
export const ListPermissionsDocument = `
    query ListPermissions($first_permissions: Int!, $first_relationTypes: Int!, $after_permissions: Cursor, $after_relationTypes: Cursor) {
  permissions(first: $first_permissions, after: $after_permissions) {
    nodes {
      displayName
      relationTypes(first: $first_relationTypes, after: $after_relationTypes) {
        nodes {
          displayName
          objectType {
            name
          }
        }
      }
    }
  }
}
    `
export const useListPermissionsQuery = <TData = ListPermissionsQuery, TError = unknown>(
  variables: ListPermissionsQueryVariables,
  options?: UseQueryOptions<ListPermissionsQuery, TError, TData>
) =>
  useQuery<ListPermissionsQuery, TError, TData>(
    ['ListPermissions', variables],
    useFetchFromDs0<ListPermissionsQuery, ListPermissionsQueryVariables>(
      ListPermissionsDocument
    ).bind(null, variables),
    options
  )

useListPermissionsQuery.getKey = (variables: ListPermissionsQueryVariables) => [
  'ListPermissions',
  variables,
]
export const useInfiniteListPermissionsQuery = <TData = ListPermissionsQuery, TError = unknown>(
  variables: ListPermissionsQueryVariables,
  options?: UseInfiniteQueryOptions<ListPermissionsQuery, TError, TData>
) => {
  const query =
    useFetchFromDs0<ListPermissionsQuery, ListPermissionsQueryVariables>(ListPermissionsDocument)
  return useInfiniteQuery<ListPermissionsQuery, TError, TData>(
    ['ListPermissions.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  )
}

useInfiniteListPermissionsQuery.getKey = (variables: ListPermissionsQueryVariables) => [
  'ListPermissions.infinite',
  variables,
]
export const AddRelationInstanceDocument = `
    mutation addRelationInstance($sourceId: ID!, $name: String!, $objectTypeName: String, $targetId: ID!) {
  setRelation(
    relation: {source: {id: $sourceId}, relationType: {name: {name: $name, objectType: {name: $objectTypeName}}}, target: {id: $targetId}}
  ) {
    relation {
      type {
        id
        name
      }
    }
  }
}
    `
export const useAddRelationInstanceMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AddRelationInstanceMutation,
    TError,
    AddRelationInstanceMutationVariables,
    TContext
  >
) =>
  useMutation<AddRelationInstanceMutation, TError, AddRelationInstanceMutationVariables, TContext>(
    ['addRelationInstance'],
    useFetchFromDs0<AddRelationInstanceMutation, AddRelationInstanceMutationVariables>(
      AddRelationInstanceDocument
    ),
    options
  )
export const RemoveRelationDocument = `
    mutation RemoveRelation($sourceId: ID!, $targetId: ID!, $relationTypeId: TypeID!) {
  deleteRelation(
    relation: {source: {id: $sourceId}, relationType: {id: $relationTypeId}, target: {id: $targetId}}
  ) {
    deleted
  }
}
    `
export const useRemoveRelationMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RemoveRelationMutation,
    TError,
    RemoveRelationMutationVariables,
    TContext
  >
) =>
  useMutation<RemoveRelationMutation, TError, RemoveRelationMutationVariables, TContext>(
    ['RemoveRelation'],
    useFetchFromDs0<RemoveRelationMutation, RemoveRelationMutationVariables>(
      RemoveRelationDocument
    ),
    options
  )
export const CreateObjectDocument = `
    mutation CreateObject($objectTypeName: String!, $key: String!, $displayName: String!, $properties: Map! = {}) {
  setObject(
    object: {type: {name: $objectTypeName}, key: $key, displayName: $displayName, properties: $properties}
  ) {
    object {
      id
    }
  }
}
    `
export const useCreateObjectMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateObjectMutation,
    TError,
    CreateObjectMutationVariables,
    TContext
  >
) =>
  useMutation<CreateObjectMutation, TError, CreateObjectMutationVariables, TContext>(
    ['CreateObject'],
    useFetchFromDs0<CreateObjectMutation, CreateObjectMutationVariables>(CreateObjectDocument),
    options
  )
export const UpdateObjectDocument = `
    mutation UpdateObject($object: SetObjectInput!) {
  setObject(object: $object) {
    object {
      id
    }
  }
}
    `
export const useUpdateObjectMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateObjectMutation,
    TError,
    UpdateObjectMutationVariables,
    TContext
  >
) =>
  useMutation<UpdateObjectMutation, TError, UpdateObjectMutationVariables, TContext>(
    ['UpdateObject'],
    useFetchFromDs0<UpdateObjectMutation, UpdateObjectMutationVariables>(UpdateObjectDocument),
    options
  )
