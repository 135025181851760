import './App.css'

import React, { Suspense } from 'react'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Navigate, Route, Routes } from 'react-router-dom'

import { NavBar } from './components/common/NavBar'
import SuccessBannerProvider from './components/common/SuccessBannerProvider'
import CreateOrganizationModal from './components/tenants/CreateOrganizationModal'
import { useProfile } from './services/ProfileAndQueryClientProvider'
import useIsGettingStartedAvailable from './views/GettingStarted/useIsGettingStartedAvailable'

const AccountSettings = React.lazy(() => import('./views/AccountSettings'))
const AssociateRegistryImage = React.lazy(() => import('./views/AssociateRegistryImage'))
const CloneRegistryImage = React.lazy(() => import('./views/CloneRegistryImage'))
const Connections = React.lazy(() => import('./views/Connections'))
const CreatePolicy = React.lazy(() => import('./views/CreatePolicy'))
const CreatePolicyEmpty = React.lazy(() => import('./views/CreatePolicyEmpty'))
const CreatePolicyFromImage = React.lazy(() => import('./views/CreatePolicyFromImage'))
const CreatePolicyFromSample = React.lazy(() => import('./views/CreatePolicyFromSample'))
const CreatePolicyFromTemplate = React.lazy(() => import('./views/CreatePolicyFromTemplate'))
const CreatePolicyInstance = React.lazy(() => import('./views/CreatePolicyInstance'))
const CreateTenant = React.lazy(() => import('./views/CreateTenant'))
const DirectoryBrowser = React.lazy(() => import('./views/DirectoryBrowser'))
const DirectoryBrowser_v2 = React.lazy(() => import('./views/DirectoryBrowser_v2'))
const EditPolicyInstance = React.lazy(() => import('./views/EditPolicyInstance'))
const Error = React.lazy(() => import('./views/Error'))
const GettingStarted = React.lazy(() => import('./views/GettingStarted'))
const RegistryImageDetails = React.lazy(() => import('./views/RegistryImageDetails'))
const RegistryImages = React.lazy(() => import('./views/RegistryImages'))
const InvitationDetails = React.lazy(() => import('./views/InvitationDetails'))
const Logout = React.lazy(() => import('./views/Logout'))
const ManageTenant = React.lazy(() => import('./views/ManageTenant'))
const Policies = React.lazy(() => import('./views/Policies'))
const PolicyInstance = React.lazy(() => import('./views/PolicyInstance'))
const Quickstart = React.lazy(() => import('./views/Quickstart'))
const QuickstartsPage = React.lazy(() => import('./views/QuickstartsPage'))
const TodoPolicyQuickstart = React.lazy(() => import('./views/TodoPolicyQuickstart'))
const TodoPolicyAppDownload = React.lazy(() => import('./views/TodoPolicyAppDownload'))
const UserView = React.lazy(() => import('./views/UserView'))
const AuthorizersView = React.lazy(() => import('./views/Authorizers'))

function App() {
  const { tenant } = useProfile()
  const { isEnabled: isGettingStartedEnabled } = useIsGettingStartedAvailable()

  return (
    <div id="app">
      <NavBar />
      <CreateOrganizationModal />
      <SuccessBannerProvider>
        <Suspense fallback={<></>}>
          <Routes>
            <Route element={<AuthorizersView />} path="ui/authorizers" />
            <Route element={<Logout />} path="/logout" />
            <Route element={<Error />} path="/error" />
            <Route element={isGettingStartedEnabled ? <GettingStarted /> : <Policies />} path="/" />
            <Route element={<Policies />} path="/ui/policies" />
            <Route element={<CreatePolicyInstance />} path="/ui/createpolicyinstance" />
            <Route element={<PolicyInstance />} path="/ui/policies/:id/*" />
            <Route element={<EditPolicyInstance />} path="/ui/policies/:id/edit" />
            <Route element={<CreatePolicy />} path="/ui/createpolicy" />
            <Route element={<Connections />} path="/ui/connections" />
            <Route element={<UserView />} path="/ui/directory/:id" />
            <Route element={<CreatePolicyEmpty />} path="/ui/createpolicy/empty" />
            <Route element={<CreatePolicyFromImage />} path="/ui/createpolicy/fromimage" />
            <Route element={<CreatePolicyFromSample />} path="/ui/createpolicy/fromsample" />
            <Route element={<CreatePolicyFromTemplate />} path="/ui/createpolicy/fromtemplate" />
            <Route element={<CreateTenant />} path="/ui/createtenant" />
            <Route element={<DirectoryBrowser />} path="/ui/directory" />
            <Route element={<DirectoryBrowser_v2 />} path="/ui/directory_v2/*" />
            <Route element={<QuickstartsPage />} path="/ui/quickstarts" />
            <Route element={<Quickstart />} path="/ui/quickstarts/:id" />
            <Route element={<TodoPolicyQuickstart />} path="/ui/getting-started/todo-policy" />
            <Route
              element={<TodoPolicyAppDownload />}
              path="/ui/getting-started/todo-app-download"
            />

            <Route element={<GettingStarted />} path="/ui/getting-started" />
            <Route element={<RegistryImages />} path="/ui/images" />
            <Route element={<RegistryImages />} path="/ui/images/:connectionId" />
            <Route
              element={<RegistryImageDetails />}
              path="/ui/images/:connectionId/:organization/:name"
            />
            <Route element={<CloneRegistryImage />} path="/ui/images/clone" />
            <Route
              element={<AssociateRegistryImage />}
              path="/ui/images/:connectionId/:organization/:name/associate"
            />
            {tenant?.personal === false && [
              <Route key="/ui/manage-tenant" element={<ManageTenant />} path="/ui/manage-tenant" />,
            ]}
            <Route element={<AccountSettings />} path="/ui/account-settings" />
            <Route element={<InvitationDetails />} path="/ui/invitation-details/:id" />
            <Route element={<InvitationDetails />} path="/ui/invitation-details" />
            <Route element={<Navigate replace to="/" />} path="*" />
          </Routes>
        </Suspense>
      </SuccessBannerProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </div>
  )
}

export default App
