import { useQuery } from 'react-query'

import { useAccountClient } from '../clients/AccountClient'
import { QueryKeys, reactQueryDefaultOptions } from '../types/local/general'
import { GetAccountResponse } from '../types/local/tenant'

export const useAccount = () => {
  const { get } = useAccountClient()
  return useQuery(
    [QueryKeys.Account],
    () => get<GetAccountResponse>({ path: 'account' }),
    reactQueryDefaultOptions
  )
}
