import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Dropdown, Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useNavigate } from 'react-router-dom'

import { useAccountInvites } from '../../../api/members'
import AsertoLogo from '../../../assets/aserto-horizontal-white-text.svg'
import separator from '../../../assets/separator.svg'
import { useIdentity } from '../../../services/IdentityProvider'
import { useProfile } from '../../../services/ProfileAndQueryClientProvider'
import { theme } from '../../../theme'
import { AccountTenantInvite } from '../../../types/local/tenant'
import { useDispatchContext } from '../../../ui/state/context/DispatchContext'
import { isInviteAcceptable } from '../../../utils/invites'
import useIsGettingStartedAvailable from '../../../views/GettingStarted/useIsGettingStartedAvailable'
import { UndecoratedLink } from '../../layout/UndecoratedLink'
import AsertoNavBar from '../AsertoNavBar'
import Select, { ReactSelectElement, SelectOption } from '../Select'
import ApiDocsDropdownItem from './ApiDocsDropdownItem'
import { DropdownMenu, ImgBadge, StyledDropdown, TenantInputContainer } from './styles'

export const NavBar = () => {
  const dispatch = useDispatchContext()
  const navigate = useNavigate()
  const { logout, user } = useIdentity()
  const tenantSelectRef = useRef<ReactSelectElement>(null)
  const { account, setTenantId, tenant } = useProfile()
  const { data: accountInvitesData } = useAccountInvites()
  const { isEnabled: isGettingStartedEnabled } = useIsGettingStartedAvailable()

  const acceptableInvites = useMemo(() => {
    const invites: Array<AccountTenantInvite> = accountInvitesData?.results ?? []
    return invites.filter(isInviteAcceptable)
  }, [accountInvitesData?.results])

  const checkActive = (_match: unknown, location: { pathname: string }) => {
    if (!location) {
      return false
    }
    const { pathname } = location
    const route = window.location.pathname
    if (!route) {
      return false
    }

    if (
      route === '/' &&
      ((isGettingStartedEnabled && pathname === '/ui/getting-started') ||
        (!isGettingStartedEnabled && pathname === '/ui/policies'))
    ) {
      return true
    }

    return route === pathname || route.startsWith(`${pathname}/`)
  }

  const customLink = (to: string, id: string, children: React.ReactNode) => {
    let className = ''
    if (checkActive(null, { pathname: to })) {
      className = 'router-link-exact-active'
    }

    return (
      <LinkContainer to={to}>
        <Nav.Link className={className} id={id}>
          <div style={{ whiteSpace: 'nowrap' }}>{children}</div>
        </Nav.Link>
      </LinkContainer>
    )
  }

  const GettingStartedLink = () => {
    return customLink('/ui/getting-started', 'nav-getting-started', 'Getting Started')
  }

  const PoliciesLink = () => {
    return customLink('/ui/policies', 'nav-authorizer', 'Policies')
  }

  const DirectoryLink = () => {
    return customLink('/ui/directory', 'nav-directory', 'Directory')
  }

  const ConnectionsLink = () => {
    return customLink('/ui/connections', 'nav-connections', 'Connections')
  }

  const ImagesLink = () => {
    return customLink('/ui/images', 'nav-images', 'Images')
  }

  const AuthorizersLink = () => {
    return customLink('/ui/authorizers', 'nav-images', 'Authorizers')
  }

  const tenants: Array<SelectOption> = useMemo(() => {
    return (
      account?.tenants
        ?.sort((tenant, otherTenant) => {
          if (tenant.personal && !otherTenant.personal) {
            return -1
          }

          if (!tenant.personal && otherTenant.personal) {
            return 1
          }

          return tenant.name!.localeCompare(otherTenant.name!)
        })
        .map((tenant) => ({
          value: tenant.id!,
          label: tenant.personal ? `${tenant.name!} (account)` : tenant.name!,
        })) ?? []
    )
  }, [account?.tenants])

  const options = useMemo(() => {
    if (account?.tenants?.length === 1) {
      return [
        {
          label: '',
          options: [
            {
              label: 'Create an organization',
              value: 'create',
              shouldStopPropagation: true,
              onClick: () => {
                dispatch({ type: 'SET_CURRENT_MODAL', modal: { type: 'CREATE_ORGANIZATION' } })
              },
            },
          ],
        },
      ]
    }
    return [
      {
        options: [
          {
            label: 'Create an organization',
            value: 'create',
            shouldStopPropagation: true,
            onClick: () => {
              dispatch({ type: 'SET_CURRENT_MODAL', modal: { type: 'CREATE_ORGANIZATION' } })
            },
          },
          ...(!tenant?.personal
            ? [
                {
                  label: 'Manage organization',
                  value: 'manage',
                  shouldStopPropagation: true,
                  onClick: () => navigate('/ui/manage-tenant'),
                },
              ]
            : []),
        ],
      },
      {
        label: 'Organizations',
        options: tenants,
      },
    ]
  }, [account?.tenants?.length, dispatch, navigate, tenant?.personal, tenants])

  useEffect(() => {
    if (tenant?.id) {
      tenantSelectRef?.current?.setValue(
        tenants.find((option: SelectOption) => option?.value === tenant?.id)!,
        'select-option'
      )
    }
  }, [tenant, tenants])

  const onChangeTenantId = useCallback(
    (data: SelectOption | null) => {
      if (data?.value && data?.value !== tenant?.id) {
        setTenantId(String(data.value))
      }
    },
    [setTenantId, tenant?.id]
  )

  return (
    <AsertoNavBar
      expand="xl"
      logo={
        <a href="/">
          <img src={AsertoLogo} />
        </a>
      }
      uncollapsableContent={
        <TenantInputContainer>
          <Select
            ref={tenantSelectRef}
            defaultValue={tenants?.find((option: SelectOption) => option?.value === tenant?.id)}
            formatGroupLabel={(a) => {
              return !!a?.label ? (
                <div
                  style={{
                    alignItems: 'center',
                    borderBottom: '1px #414141 solid',
                    borderTop: '1px #414141 solid',
                    color: theme.grey100,
                    display: 'flex',
                    fontSize: 14,
                    fontWeight: 'bold',
                    height: '100%',
                    minHeight: '36px',
                  }}
                >
                  {a.label}
                </div>
              ) : undefined
            }}
            isSearchable={false}
            modifyCustomStyle={(style) => {
              if (!style.groupHeading) {
                return {}
              }
              return {
                ...style,
                groupHeading: (styles) => {
                  return {
                    ...styles,
                    backgroundColor: '#2a2a2a',
                    margin: 0,
                  }
                },
              }
            }}
            options={options}
            placeholder="Select tenant"
            style={{
              width: '100%',
              height: 35,
            }}
            onChange={onChangeTenantId}
          />
          <img alt="separator" src={separator} />
        </TenantInputContainer>
      }
    >
      <Nav as="ul" className="mr-auto">
        <>
          {isGettingStartedEnabled && (
            <Nav.Item as="li">
              <GettingStartedLink />
            </Nav.Item>
          )}
          <Nav.Item as="li">
            <PoliciesLink />
          </Nav.Item>
          <Nav.Item as="li">
            <ImagesLink />
          </Nav.Item>
          <Nav.Item as="li">
            <DirectoryLink />
          </Nav.Item>
          <Nav.Item as="li">
            <AuthorizersLink />
          </Nav.Item>
          <Nav.Item as="li">
            <ConnectionsLink />
          </Nav.Item>
        </>
        <Nav.Item as="li" className="d-lg-none d-xl-none d-md-block">
          <LinkContainer to="/ui/quickstarts">
            <Nav.Link>Quickstarts</Nav.Link>
          </LinkContainer>
        </Nav.Item>
        <Nav.Item as="li" className="d-lg-none d-xl-none d-md-block">
          <LinkContainer to="/ui/account-settings">
            <Nav.Link>Account settings</Nav.Link>
          </LinkContainer>
        </Nav.Item>
        <Nav.Item as="li" className="d-lg-none d-xl-none d-md-block">
          <Nav.Link onClick={logout}>Sign out</Nav.Link>
        </Nav.Item>
      </Nav>
      <Nav className="d-none d-md-block">
        <StyledDropdown className="d-md-none d-lg-block">
          <Dropdown.Toggle as={Nav.Link} className="toggle-profile position-relative">
            {acceptableInvites.length > 0 && <ImgBadge />}
            <img
              alt="Profile"
              className="nav-user-profile rounded-circle"
              height="41"
              src={user?.picture}
              width="41"
            />
          </Dropdown.Toggle>
          <DropdownMenu align="right">
            <Dropdown.Header>{user?.name}</Dropdown.Header>
            <UndecoratedLink to="/ui/account-settings">
              <Dropdown.Item as="li">
                <i className="fa fa-user mr-3" /> Account settings
              </Dropdown.Item>
            </UndecoratedLink>

            <UndecoratedLink to="/ui/quickstarts">
              <Dropdown.Item as="li">
                <i className="fa fa-play mr-3" /> Quickstarts
              </Dropdown.Item>
            </UndecoratedLink>
            {tenant !== null && <ApiDocsDropdownItem tenant={tenant} />}
            <Dropdown.Item id="qsLogoutBtn" onClick={logout}>
              <i className="fa fa-power-off mr-3" /> Sign out
            </Dropdown.Item>
          </DropdownMenu>
        </StyledDropdown>
      </Nav>
    </AsertoNavBar>
  )
}
