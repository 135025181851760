import { useCallback, useMemo } from 'react'
import { useAserto as useAsertoSdk } from '@aserto/aserto-react'

import { useConfig } from '../services/ConfigProvider'
import { useIdentity } from '../services/IdentityProvider'

export function useAserto() {
  const { getAccessToken } = useIdentity()
  const { init, reload } = useAsertoSdk()
  const { backendServiceUrl } = useConfig()

  const callInit = useCallback(
    async (tenantId: string): Promise<void> => {
      const token = await getAccessToken()
      const options = {
        serviceUrl: backendServiceUrl,
        accessToken: token,
        throwOnError: true,
      }
      const body = JSON.stringify({ 'Aserto-Tenant-Id': tenantId })
      await init(options, body)
    },
    [getAccessToken, backendServiceUrl, init]
  )

  const callReload = useCallback(
    async (tenantId: string): Promise<void> => {
      const body = JSON.stringify({ 'Aserto-Tenant-Id': tenantId })
      await reload(body)
    },
    [reload]
  )

  return useMemo(
    () => ({
      init: callInit,
      reload: callReload,
    }),
    [callInit, callReload]
  )
}
