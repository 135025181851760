import { createGlobalStyle } from 'styled-components'

import { theme } from './theme'

export const GlobalStyle = createGlobalStyle`
  #root,
  #app {
    height: 100%;
  }
  html,
  body {
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${theme.primaryBlack};
    color: ${theme.grey70};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme.grey100};
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: ${theme.grey10};
  }

  &::-webkit-scrollbar-corner {
    background-color: ${theme.grey10};
  }

  &::-webkit-scrollbar-thumb {
    background: ${theme.grey40};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    margin: 4px;
    border-radius: 10px;
  }

  code {
    font-family: 'Ubuntu Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    color: ${theme.lochivarAccent4};
  }

  /* link treatment */
  a {
    color: ${theme.lochivarAccent4};
  }
  a:hover {
    text-decoration: underline;
    color: #3ecacf
  }

  textarea {
    resize: none;
  }

  @media (min-width: 800px) {

    .left-item:after {
      content: "";
      background: #414141;
      position: absolute;
      bottom: 16px;
      height: 50%;
      right: 206px;
      width: 1px;
    }

    .nav-item {
      height: 60px !important;
    }

  }

`
