import { useDs0AccountProperties } from '../../api/ds0Directory'
import { useProfile } from '../../services/ProfileAndQueryClientProvider'

const useIsGettingStartedAvailable = () => {
  const ds0User = useDs0AccountProperties()
  const { tenant } = useProfile()

  const isEnabled =
    ds0User === undefined || tenant === null
      ? undefined
      : ds0User.properties.getting_started.show && tenant.personal
  return {
    isEnabled,
  }
}

export default useIsGettingStartedAvailable
