import { useQuery } from 'react-query'

import { useInfoClient } from '../clients/InfoClient'
import { QueryKeys, reactQueryDefaultOptions } from '../types/local/general'
import { InfoResponse } from '../types/local/tenant'

export const useInfo = () => {
  const { get } = useInfoClient()

  return useQuery([QueryKeys.Info], async () => await get<InfoResponse>({ path: 'info' }), {
    ...reactQueryDefaultOptions,
    retry: false,
    staleTime: Infinity,
  })
}
