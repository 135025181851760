import React, { useContext } from 'react'

import { AppAction } from '..'

const DispatchContext = React.createContext<React.Dispatch<AppAction>>(() => {})

export const DispatchContextProvider = ({
  children,
  dispatch,
}: React.PropsWithChildren<{ dispatch: React.Dispatch<AppAction> }>) => {
  return <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
}

export function useDispatchContext() {
  return useContext(DispatchContext)
}
