import React, { useReducer } from 'react'

import { getInitialState, reducer } from '..'
import { ContextProviderProps } from './ContextProviderProps'
import { CurrentModalContextProvider } from './CurrentModalContext'
import { DispatchContextProvider } from './DispatchContext'

export const AppStateContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [appState, dispatch] = useReducer(reducer, getInitialState())

  const contextProviders: Array<React.ComponentType<ContextProviderProps>> = [
    CurrentModalContextProvider,
    // Add more context providers here
  ]

  for (const ContextProvider of contextProviders) {
    children = (
      <ContextProvider appState={appState} dispatch={dispatch}>
        {children}
      </ContextProvider>
    )
  }

  return <DispatchContextProvider dispatch={dispatch}>{children}</DispatchContextProvider>
}
