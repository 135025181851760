import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

import { useDeviceDetect } from '../../../utils/general'
import { Card, Variant } from '../Card'

const CardModalContainer = styled(Modal)`
  overflow: hidden;
  border-radius: 20px;
  .modal-content {
    border-radius: 20px;
  }
`

export type CardModalProps = {
  title: string
  onHide?: () => void
  children: string | React.ReactElement
  show?: boolean
  cardHeight?: number | string
  text?: string | React.ReactElement
  variant?: Variant
}

const CardModal: React.FC<CardModalProps> = ({
  children,
  title,
  show,
  onHide,
  cardHeight,
  text,
  variant,
}) => {
  const { isMobile } = useDeviceDetect()
  const minHeight = String(isMobile ? 225 : 215) + 'px'
  return (
    <CardModalContainer centered data-testid="modal" show={show} onHide={onHide}>
      <Card
        body={children}
        height={cardHeight}
        style={{
          minHeight,
          width: '100%',
        }}
        text={text}
        title={title}
        variant={variant}
      />
    </CardModalContainer>
  )
}

export { CardModal }
