import { useQuery, UseQueryOptions } from 'react-query'

import { useConfigClient } from '../clients/ConfigClient'
import Config from '../types/local/config'
import { QueryKeys } from '../types/local/general'
import { RpcStatus } from '../types/local/tenant'

export const useConfig = (
  options?: Omit<UseQueryOptions<Config, RpcStatus, Config, QueryKeys[]>, 'queryKey' | 'queryFn'>
) => {
  const { get } = useConfigClient()

  const config = useQuery([QueryKeys.Config], () => get<Config>({ path: 'config' }), {
    ...options,
    retry: false,
    staleTime: Infinity,
  })

  return config
}
