import { useProviders } from '../../api/connections'
import {
  ConfigElement,
  Connection,
  ConnectionKind,
  Provider,
  ProviderKind,
} from '../../types/local/tenant'

export interface ConnectionProviderConfig extends ConfigElement {
  value: string
}

export const getProviderForConnection = (
  providers: Array<Provider>,
  connection: Connection
): Provider => {
  const provider = providers.find((provider) => provider?.id === connection?.provider_id)
  if (!provider) {
    throw Error('could not find provider for connection')
  }
  return provider
}

export const useProviderById = (providerId: string | undefined) => {
  const providers = useProviders()
  if (!providers.data?.results || !providerId) {
    return undefined
  }

  return getProviderById(providers.data.results, providerId)
}

export const mergeConnectionConfigWithProviderConfig = (
  provider: Provider,
  connection: Connection | null
): Array<ConnectionProviderConfig> => {
  const connectionConfig = connection?.config ?? {}
  return provider.config!.map((providerConfigElement) => ({
    ...providerConfigElement,
    value: String(connectionConfig[providerConfigElement.name!] ?? ''),
  }))
}

export type NonSystemConnectionFilter =
  | 'ALL_CONNECTIONS'
  | 'IDENTITY_PROVIDER'
  | 'SOURCE_CODE_PROVIDER'
  | 'POLICY_REGISTRY'
  | 'EDGE_AUTHORIZER'

export type ConnectionFilter = NonSystemConnectionFilter | 'SYSTEM_CONNECTION'

interface ConnectionFilterDefinition {
  labelText: string
  emptyText: string
  providerKind?: ProviderKind
  filterFunction: (connection: Connection) => boolean
}

export const NonSystemConnectionFilterDefinitions: Record<
  NonSystemConnectionFilter,
  ConnectionFilterDefinition
> = {
  ALL_CONNECTIONS: {
    labelText: 'All',
    emptyText: "You don't have any connections connected",
    filterFunction: () => true,
  },
  IDENTITY_PROVIDER: {
    labelText: 'Identity Providers',
    emptyText: "You don't have any identity providers connected",
    providerKind: 'PROVIDER_KIND_IDP',
    filterFunction: (connection) => connection.kind === 'PROVIDER_KIND_IDP',
  },
  SOURCE_CODE_PROVIDER: {
    labelText: 'Source Code Providers',
    emptyText: "You don't have any source code providers connected",
    providerKind: 'PROVIDER_KIND_SCC',
    filterFunction: (connection) => connection.kind === 'PROVIDER_KIND_SCC',
  },
  POLICY_REGISTRY: {
    labelText: 'Policy Registries',
    emptyText: "You don't have any policy registries connected",
    providerKind: 'PROVIDER_KIND_POLICY_REGISTRY',
    filterFunction: (connection) => connection.kind === 'PROVIDER_KIND_POLICY_REGISTRY',
  },
  EDGE_AUTHORIZER: {
    labelText: 'Edge Authorizers',
    emptyText: "You don't have any edge authorizers connected",
    providerKind: 'PROVIDER_KIND_EDGE_AUTHORIZER',
    filterFunction: (connection) => connection.kind === 'PROVIDER_KIND_EDGE_AUTHORIZER',
  },
}

export const ConnectionFilterDefinitions: Record<ConnectionFilter, ConnectionFilterDefinition> = {
  ...NonSystemConnectionFilterDefinitions,
  SYSTEM_CONNECTION: {
    labelText: 'System Connections',
    emptyText: "You don't have any system connections connected",
    filterFunction: (connection) => connection.system === true,
  },
}

export const CONNECTION_FILTER_OPTIONS = Object.entries(ConnectionFilterDefinitions).map(
  ([value, { labelText: label }]) => ({ label, value: value as ConnectionFilter })
)

export const NON_SYSTEM_CONNECTION_FILTER_OPTIONS = Object.entries(
  NonSystemConnectionFilterDefinitions
).map(([value, { labelText: label }]) => ({ label, value: value as NonSystemConnectionFilter }))

export const isConnectionNameAvailable = (
  connections: Array<Connection>,
  connectionName: string
): boolean => {
  return !connections.some((connection) => connection.name === connectionName)
}

export const getProviderById = (providers: Array<Provider>, id: string): Provider => {
  const provider = providers.find((provider: Provider) => provider.id === id)
  if (provider) {
    return provider
  }
  throw new Error('provider not found')
}

export const getConnectionsByKind = (connections: Array<Connection>, kind: ConnectionKind) => {
  return connections.filter((connection) => connection.kind === kind && connection.verified)
}
