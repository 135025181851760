import React, { useState } from 'react'

import sadAxolotl from '../../assets/axolotl.svg'
import Button from '../../components/common/Button'
import { Card } from '../../components/common/Card'
import { ApiError } from '../../lib/error/ApiError'
import { InformationalError } from '../../lib/error/InformationalError'
import Details from './Details'
import Overview from './Overview'
import {
  BodyContainer,
  ButtonContainer,
  CardContent,
  CardModalContainer,
  MessageContainer,
  Tab,
  TabContainer,
} from './Styles'

interface ErrorModalProps {
  error: Error | null
  onClose: () => void
}

const ErrorModal: React.FC<ErrorModalProps> = ({ error, onClose }) => {
  const [tab, setTab] = useState<'overview' | 'details'>('overview')

  return (
    <CardModalContainer centered data-testid="error-modal" show={error !== null}>
      <Card
        body={
          <CardContent>
            <BodyContainer>
              <img src={sadAxolotl} />
              <MessageContainer>
                <TabContainer>
                  <Tab checked={tab === 'overview'} onClick={() => setTab('overview')}>
                    Overview
                  </Tab>
                  {error instanceof ApiError && error.metadata !== null && (
                    <Tab checked={tab === 'details'} onClick={() => setTab('details')}>
                      Details
                    </Tab>
                  )}
                </TabContainer>
                {error === null ? null : tab === 'overview' ? (
                  <Overview error={error} />
                ) : (
                  // Details tab is only viewable if the error is an ApiError and metadata exists
                  <Details items={(error as ApiError).metadata!} />
                )}
              </MessageContainer>
            </BodyContainer>
            <ButtonContainer>
              <Button variant="secondary" onClick={onClose}>
                Close
              </Button>
            </ButtonContainer>
          </CardContent>
        }
        height={600}
        title={(error as InformationalError)?.title ?? 'Something went wrong'}
        variant="danger"
        width={700}
      />
    </CardModalContainer>
  )
}

export default ErrorModal
