import { useConfig } from '../services/ConfigProvider'
import { useAsertoApiWithAuth0Token } from './AsertoApiWithAuth0Token'

function useCentralDirectoryClient(directoryUrl: string) {
  return useAsertoApiWithAuth0Token(directoryUrl)
}

type GQLBody<TVariables> = {
  query: string
  variables?: TVariables
}

type GQLResponse<TData> = {
  errors: { message: string }[]
  data: TData
}

const useFetchFromDirectory = <TData, TVariables>(
  directoryUrl: string,
  query: string,
  options?: RequestInit['headers']
): ((variables?: TVariables) => Promise<TData>) => {
  const client = useCentralDirectoryClient(directoryUrl)
  const headers = new Headers(options)
  const headerOverrides: Record<string, string> = {}
  headers.forEach((value, key) => (headerOverrides[key] = value))
  return async (variables?: TVariables) => {
    const body = {
      query,
      variables,
    }

    const response = await client.post<GQLResponse<TData>, GQLBody<TVariables>>({
      body,
      headerOverrides,
      path: 'graph/query',
    })

    if (!!response.errors) {
      const message = response.errors.map((e) => e.message).join('\n')
      throw new Error(message)
    }
    return response.data
  }
}

export const useFetchFromDs0 = <TData, TVariables>(
  query: string,
  options?: RequestInit['headers']
): ((variables?: TVariables) => Promise<TData>) => {
  const { ds0DirectoryUrl } = useConfig()
  return useFetchFromDirectory(ds0DirectoryUrl, query, options)
}

export const useFetchFromTenantDirectory = <TData, TVariables>(
  query: string,
  options?: RequestInit['headers']
): ((variables?: TVariables) => Promise<TData>) => {
  const { asertoDirectoryUrl } = useConfig()
  return useFetchFromDirectory(asertoDirectoryUrl, query, options)
}
