import { useMutation, useQuery, useQueryClient } from 'react-query'

import { useHubSpotClient } from '../clients/HubSpotClient'
import { useOnboardingClient } from '../clients/OnboardingClient'
import AccountNameValidator from '../lib/validation/AccountNameValidator'
import {
  MutationKeys,
  QueryKeys,
  reactQueryDefaultOptions,
  ReactQueryMutationHooks,
  useMutationOptions,
} from '../types/local/general'
import {
  ClaimTenantRequestBody,
  ClaimTenantResponse,
  TenantAvailableResponse,
} from '../types/local/tenant'

export const useValidateTenantName = (accountName: string) => {
  const { get } = useOnboardingClient()
  return useQuery(
    [QueryKeys.CreateAccountNameValidation, accountName],
    () => {
      return AccountNameValidator.isValid(accountName)
        ? get<TenantAvailableResponse>({ path: `available/${accountName}` })
        : Promise.resolve<TenantAvailableResponse>({
            availability: 'TENANT_AVAILABILITY_INVALID',
            reason: 'Failed local validity check',
          })
    },
    reactQueryDefaultOptions
  )
}

export const useClaimTenant = (
  mutationHooks: ReactQueryMutationHooks<ClaimTenantResponse, ClaimTenantRequestBody> = {}
) => {
  const { hubspotSetProperty } = useHubSpotClient()
  const queryClient = useQueryClient()
  const mutationOptions = useMutationOptions({
    ...mutationHooks,
    onSuccess: async (result, { personal, name }, context) => {
      queryClient.invalidateQueries(QueryKeys.Account)
      const operation = personal ? 'created_account' : 'created_organization'
      try {
        await hubspotSetProperty({
          name: operation,
          value: name,
        })
      } catch (e) {}
      mutationHooks.onSuccess?.(result, { personal, name }, context)
    },
  })

  const { post } = useOnboardingClient()
  return useMutation(
    MutationKeys.ClaimTenant,
    (body: ClaimTenantRequestBody) =>
      post<ClaimTenantResponse, ClaimTenantRequestBody>({ body, path: `claim` }),
    mutationOptions
  )
}
