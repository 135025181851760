import { z } from 'zod'

import { SetObjectInput } from '../../api/ds0Directory'
import { GettingStartedStep } from '../../views/GettingStarted/useGettingStartedState'

const Ds0UserPropertiesSchema = z
  .object({
    getting_started: z
      .object({
        show: z.boolean().default(true),
        steps: z
          .record(
            z.nativeEnum(GettingStartedStep),
            z.object({
              completed: z.boolean().default(false),
            })
          )
          .default({}),
      })
      .default({ show: true, steps: {} }),
  })
  .passthrough()

type Ds0UserProperties = z.infer<typeof Ds0UserPropertiesSchema>

export type Ds0User = SetObjectInput & { properties: Ds0UserProperties }

export const parseAsDs0UserProperties = (target: unknown = {}): Ds0UserProperties =>
  Ds0UserPropertiesSchema.parse(target)
