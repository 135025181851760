import React, { PropsWithChildren, useCallback, useContext, useState } from 'react'

import close from '../../../assets/close.svg'
import { SuccessContent } from './style'

type SuccessBannerContextProps = {
  clearMessage: () => void
  showMessage: (message: string) => void
}

type SuccessBannerProviderProps = PropsWithChildren<{}>

const SuccessMessageContext = React.createContext<SuccessBannerContextProps>({
  clearMessage: () => {},
  showMessage: () => {},
})

export const useShowSuccessMessage = () => {
  const { showMessage } = useContext(SuccessMessageContext)
  return showMessage
}

const SuccessBannerProvider: React.FC<SuccessBannerProviderProps> = ({ children }) => {
  const [message, setMessage] = useState<string | null>(null)
  const clearMessage = useCallback(() => setMessage(null), [])
  const showMessage = useCallback((message: string) => setMessage(message), [])

  return (
    <SuccessMessageContext.Provider value={{ clearMessage, showMessage }}>
      <SuccessContent show={!!message}>
        {message}
        <img alt="close success banner" src={close} onClick={clearMessage} />
      </SuccessContent>
      {children}
    </SuccessMessageContext.Provider>
  )
}

export default SuccessBannerProvider
