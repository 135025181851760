import React from 'react'

import rainLottie from '../../assets/rain-lottie.png'
import PaddedContent from '../../components/common/PaddedContent'
import { CenterContainer, ImageContainer, LottieImg } from './styles'

const DisabledAccount: React.FC = () => {
  return (
    <PaddedContent>
      <CenterContainer>
        <ImageContainer>
          <LottieImg src={rainLottie} />
        </ImageContainer>

        <br></br>
        <h1>Your account is disabled.</h1>
        <h3>
          Please contact us at <a href="mailto:support@aserto.com">support@aserto.com</a> if you
          wish to restore it.
        </h3>
        <br></br>
      </CenterContainer>
    </PaddedContent>
  )
}
export default DisabledAccount
