import { useMutation, useQuery, useQueryClient } from 'react-query'

import { useAccountClient } from '../clients/AccountClient'
import { useConsoleBackendClient } from '../clients/ConsoleBackendClient'
import { useHubSpotClient } from '../clients/HubSpotClient'
import { useTenantClient } from '../clients/TenantClient'
import { useProfile } from '../services/ProfileAndQueryClientProvider'
import { GetRolesResponse } from '../types/local/consoleBackend'
import { QueryKeys, reactQueryDefaultOptions } from '../types/local/general'
import {
  GetInvitesResponse,
  InviteStatus,
  InviteUserRequestBody,
  InviteUserResponse,
  ListInvitesResponse,
  RespondToInviteResponse,
} from '../types/local/tenant'

export const useInviteMember = () => {
  const queryClient = useQueryClient()
  const { post } = useTenantClient()
  const { hubspotCreateContact } = useHubSpotClient()
  const { tenant } = useProfile()
  return useMutation(
    ({ ...body }: InviteUserRequestBody) => {
      return post<InviteUserResponse, InviteUserRequestBody>({ path: `profile/invites`, body })
    },
    {
      onSuccess: (data, variables) => {
        queryClient.refetchQueries([QueryKeys.InvitesData])
        try {
          hubspotCreateContact({
            email: variables.email,
            organization: tenant?.name,
          })
        } catch (e) {}
      },
    }
  )
}

export const useInvites = () => {
  const { get } = useTenantClient()
  return useQuery(
    [QueryKeys.InvitesData],
    () => get<GetInvitesResponse>({ path: `profile/invites` }),
    reactQueryDefaultOptions
  )
}

export const useAccountInvites = () => {
  const { get } = useAccountClient()
  return useQuery(
    QueryKeys.AccountInvitesData,
    () => get<ListInvitesResponse>({ path: `account/invites` }),
    reactQueryDefaultOptions
  )
}

export const useAcceptInvite = () => {
  return useChangeInviteStatus('INVITE_STATUS_ACCEPTED')
}

export const useDeclineInvite = () => {
  return useChangeInviteStatus('INVITE_STATUS_DECLINED')
}

export const useCancelInvite = () => {
  return useChangeInviteStatus('INVITE_STATUS_CANCELED')
}

const useChangeInviteStatus = (status: InviteStatus) => {
  const queryClient = useQueryClient()
  const { post } = useTenantClient()
  return useMutation<
    RespondToInviteResponse,
    unknown,
    { inviteId: string; tenantId: string },
    unknown
  >(
    ({ inviteId, tenantId }: { inviteId: string; tenantId: string }) => {
      return post({
        body: { id: inviteId, status },
        headerOverrides: { 'aserto-tenant-id': tenantId },
        path: `profile/invites/${inviteId}`,
      })
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries([QueryKeys.AccountInvitesData, QueryKeys.InvitesData])
      },
    }
  )
}

export const useDeleteTenantMember = () => {
  const { del } = useTenantClient()
  return useMutation(({ id }: { id: string }) => {
    return del({ path: `profile/members/${id}` })
  })
}

export const useRoles = () => {
  const { get } = useConsoleBackendClient()
  return useQuery(
    [QueryKeys.RolesData],
    () => get<GetRolesResponse>({ path: `roles` }),
    reactQueryDefaultOptions
  )
}
