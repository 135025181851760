import { useCallback, useMemo } from 'react'

import { useConnections, useProviders } from '../../api/connections'
import { getProviderById } from '../../lib/connection'
import {
  basicAuthConnectionMakerParams,
  useBasicAuthConnectionMaker,
} from '../../services/connectionMakers/basicAuthConnectionMaker'
import { ProviderId } from '../../types/local/hardcodedBackendConstants'
import { Connection } from '../../types/local/tenant'

type SampleConnections = {
  AcmeCorp: Connection | unknown
  Citadel: Connection | unknown
  Apcr: Connection | undefined
  PersonalIdp: Connection | undefined
}

const useSampleConnections = ({ ...params }: basicAuthConnectionMakerParams = {}) => {
  const { data: connectionsData } = useConnections()
  const { data: providersData } = useProviders()
  const makeBasicAuthConnection = useBasicAuthConnectionMaker(params)

  const tryGetConnection = useCallback(
    (providerId: string) =>
      connectionsData?.results?.find((connection) => connection.provider_id === providerId),
    [connectionsData?.results]
  )

  const personalIdp = useMemo(
    () =>
      connectionsData?.results?.find(
        (connection) =>
          connection.kind === 'PROVIDER_KIND_IDP' &&
          connection.provider_id !== ProviderId.IDPAcmeCorp &&
          connection.provider_id !== ProviderId.IDPCitadel
      ),
    [connectionsData?.results]
  )

  const sampleConnections = useMemo((): SampleConnections | undefined => {
    if (!connectionsData) {
      return undefined
    }
    return {
      Citadel: tryGetConnection(ProviderId.IDPCitadel),
      AcmeCorp: tryGetConnection(ProviderId.IDPAcmeCorp),
      Apcr: tryGetConnection(ProviderId.AsertoRegistry),
      PersonalIdp: personalIdp,
    }
  }, [connectionsData, personalIdp, tryGetConnection])

  const createConnection = useMemo(() => {
    if (!providersData) {
      return undefined
    }

    return (providerId: string) => {
      const providers = providersData?.results || []
      const selectedProvider = getProviderById(providers, providerId)
      const connectionInfo = {
        description: selectedProvider.description!,
        name: selectedProvider.name!,
        providerId,
      }
      makeBasicAuthConnection({ content: connectionInfo, selectedProvider: selectedProvider })
    }
  }, [makeBasicAuthConnection, providersData])

  return {
    sampleConnections,
    createConnection,
  }
}

export default useSampleConnections
