export const ProviderId = {
  IDPOkta: 'd45466e0-37ce-11ec-a918-046afaa494ce',
  IDPAuth0: 'd06ec4e3-9192-11eb-9788-04612a6a688b',
  IDPAcmeCorp: '6e7bdf74-3b2f-11ec-8d43-0428466d27be',
  IDPCitadel: '6dd707ac-e7bd-11ec-808a-049ec1ced886',
  SCCGithub: 'd06ec641-9192-11eb-9fb0-0414a6459e0f',
  SCCGithubPAT: 'df50abbd-f698-11eb-ad38-04cddc192c28',
  AuthorizerAsertoHosted: 'b7a0a5c3-9606-11eb-8d28-04a6fa235b01',
  PolicyRegistryAsertoHosted: 'd0f16916-9606-11eb-bdc8-045beca7660c',
  OPCR: '9e6941d0-341a-11ec-ac97-040e866de825',
  AsertoPublicPolicyImages: '0b0f42f6-9ec0-11ec-85ad-0499f9360e43',
  DecisionLogs: 'b16be162-3e7b-11ec-88a6-04b9a4df5142',
  Discovery: 'dfbd5864-4667-11ec-bb41-0493e077af85',
  Directory: '651f2678-4338-11ec-b85e-044dbf7c965f',
  AsertoRegistry: 'e59f9680-51d0-11ec-82ce-04c0f89bb450',
  GHCR: '073bca84-9e03-11ec-adc1-04354e4dab8b',
  EdgeAuthorizer: 'ac1be33b-f615-11ec-83a4-041f1f3ef1c7',
} as const

export const ProviderName = {
  'd45466e0-37ce-11ec-a918-046afaa494ce': 'okta',
  'd06ec4e3-9192-11eb-9788-04612a6a688b': 'auth0',
  '6e7bdf74-3b2f-11ec-8d43-0428466d27be': 'acmecorp',
  '6dd707ac-e7bd-11ec-808a-049ec1ced886': 'citadel',
  'd06ec641-9192-11eb-9fb0-0414a6459e0f': 'github',
  'df50abbd-f698-11eb-ad38-04cddc192c28': 'github',
  '9e6941d0-341a-11ec-ac97-040e866de825': 'opcr',
  '0b0f42f6-9ec0-11ec-85ad-0499f9360e43': 'opcr-public',
  '073bca84-9e03-11ec-adc1-04354e4dab8b': 'ghcr',
  'e59f9680-51d0-11ec-82ce-04c0f89bb450': 'apcr',
  'ac1be33b-f615-11ec-83a4-041f1f3ef1c7': 'edge-authorizer',
}

export const ProviderConfig = {
  ApiKey: 'api_key',
  ApiKeyWrite: 'api_key_write',
  ApiKeyRead: 'api_key_read',
  CertificateExpirationDate: 'certificate_expiration_date',
  DownloadKey: 'download_key',
}

export const OpcrPublicOrg = 'aserto-templates'
