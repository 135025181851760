import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

export const TabContainer = styled.ul`
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #414141;
  list-style-type: none;
  padding: 0;
`

interface TabProps {
  checked: boolean
}
export const Tab = styled.li<TabProps>`
  cursor: pointer;
  padding: 20px 0 20px 5px;
  width: 111px;
  border-bottom: 1px solid ${({ checked }) => (checked ? `#ff4a4a` : `#414141`)};
  color: ${({ checked }) => (checked ? `#e7e7e7` : `#a0a0a0`)};
`

export const CardModalContainer = styled(Modal)`
  overflow: hidden;
  border-radius: 20px;
  .modal-content {
    border-radius: 20px;
  }
`

export const CardContent = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  strong {
    color: ${({ theme }) => theme.grey100};
  }
  img {
    align-self: baseline;
    margin-right: 14px;
    width: 120px;
  }
  flex-basis: 100%;
  width: 100%;
`
export const BodyContainer = styled.div`
  display: flex;
  flex-basis: 100%;
`

export const MessageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`

export const ButtonContainer = styled.div`
  button {
    float: right;
  }
`

export const MessageText = styled.div`
  margin-bottom: 15px;
  a {
    color: ${({ theme }) => theme.lochivarAccent4};
  }
  word-break: break-word;
`
