import React from 'react'

import AsertoLogo from '../../assets/aserto-horizontal-white-text.svg'
import LottieSmiling from './lottie_smiling.svg'
import { BodyImage, FullSmallScreenModal, Header, LargeText, SmallText } from './styles'

const SmallScreenErrorModal: React.FC = () => {
  return (
    <FullSmallScreenModal>
      <Header>
        <img src={AsertoLogo} />
      </Header>
      <LargeText>
        Currently, the Aserto Console experience is optimized for desktop browsers.
      </LargeText>
      <SmallText>You still get a smile from Lottie.</SmallText>
      <BodyImage src={LottieSmiling} />
    </FullSmallScreenModal>
  )
}

export default SmallScreenErrorModal
