import { setLogger } from 'react-query'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { useConfig } from '../ConfigProvider'
import { useEnvConfig } from '../EnvConfigProvider'

const SentryEmbed = () => {
  const { isProduction, sentryDsn } = useConfig()
  const { environment } = useEnvConfig()

  if (isProduction) {
    Sentry.init({
      dsn: sentryDsn,
      environment: environment,
      integrations: [new Integrations.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    })

    setLogger({
      log: (message) => {
        Sentry.captureMessage(message)
      },
      warn: (message) => {
        Sentry.captureMessage(message)
      },
      error: (error) => {
        Sentry.captureException(error)
      },
    })
  }

  return null
}

export default SentryEmbed
