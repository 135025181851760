import * as FullStory from '@fullstory/browser'

import { useConfig } from '../ConfigProvider'

const FullStoryInit = () => {
  const config = useConfig()

  if (config.isProduction) {
    FullStory.init({ orgId: config.fullStoryOrgId })
  }
  return null
}

export default FullStoryInit
