import React, { useContext } from 'react'

import { ModalType } from '..'
import { ContextProviderProps } from './ContextProviderProps'

const CurrentModalContext = React.createContext<ModalType | null>(null)

export const CurrentModalContextProvider = ({ children, appState }: ContextProviderProps) => {
  const { currentModal } = appState
  return (
    <CurrentModalContext.Provider value={currentModal}>{children}</CurrentModalContext.Provider>
  )
}

export function useCurrentModalContext() {
  return useContext(CurrentModalContext)
}
