import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
// This is the project's abstraction around the auth0 APIs, so disabling protection
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { AppState, useAuth0, User as Auth0User } from '@auth0/auth0-react'

import DisabledAccount from '../../views/DisabledAccount'
import { useEnvConfig } from '../EnvConfigProvider'

export type User = Auth0User

type IdentityProviderProps = PropsWithChildren<{}>

type IdentityProviderContextProps =
  | {
      getAccessToken: () => Promise<string>
      logout: () => void
      user: User | undefined
    }
  | null
  | undefined

const identityProviderContext = React.createContext<IdentityProviderContextProps>(undefined)

export const useIdentity = () => {
  const auth0 = useAuth0()
  const { websiteUrl } = useEnvConfig()
  const memoryIdentity = useContext(identityProviderContext)

  if (memoryIdentity === undefined) {
    throw new Error('Identity must be retrieved in the context of an <IdentityProvider>')
  }

  if (memoryIdentity === null && !auth0) {
    throw new Error('Auth0 must be retrieved in the context of an <Auth0Provider />')
  }

  const {
    getAccessTokenSilently: auth0GetAccessToken,
    logout: auth0Logout,
    user: auth0User,
  } = auth0 || {}

  const auth0identity = {
    getAccessToken: auth0GetAccessToken,
    logout: auth0Logout,
    user: auth0User,
  }

  const { logout, getAccessToken, user } = memoryIdentity ? memoryIdentity : auth0identity

  const wrappedLogout = useCallback(() => {
    logout({
      returnTo: websiteUrl,
    })
    sessionStorage.clear()
  }, [logout, websiteUrl])

  return useMemo(
    () => ({
      getAccessToken: getAccessToken,
      logout: wrappedLogout,
      user: user,
    }),
    [getAccessToken, user, wrappedLogout]
  )
}

const IdentityProvider: React.FC<IdentityProviderProps> = ({ children }) => {
  const { loginWithRedirect, isLoading, isAuthenticated, error: auth0Error } = useAuth0()

  useEffect(() => {
    // require a logged-in user
    if (!isAuthenticated && !isLoading && !auth0Error) {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
        redirectUri: window.location.origin,
      })
    }
  }, [isLoading, isAuthenticated, auth0Error, loginWithRedirect])

  if (auth0Error) {
    return <DisabledAccount></DisabledAccount>
  }

  return isAuthenticated ? (
    <identityProviderContext.Provider value={null}>{children}</identityProviderContext.Provider>
  ) : null
}

export type MemoryIdentityProviderProps = {
  getAccessToken?: () => Promise<string>
  logout?: () => void
  user?: Auth0User
}

export const MemoryIdentityProvider: React.FC<PropsWithChildren<MemoryIdentityProviderProps>> = ({
  children,
  getAccessToken,
  logout,
  user,
}) => {
  return (
    <identityProviderContext.Provider
      value={{
        getAccessToken: getAccessToken || (() => Promise.resolve('')),
        logout: logout || (() => {}),
        user: user || undefined,
      }}
    >
      {children}
    </identityProviderContext.Provider>
  )
}

export const useOnRedirectCallback = () => {
  const navigate = useNavigate()

  return useCallback(
    (appState?: AppState | undefined) => {
      navigate(appState?.returnTo || window.location.pathname)
    },
    [navigate]
  )
}

export default IdentityProvider
