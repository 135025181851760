import { useCallback } from 'react'

import { useIdentity } from '../services/IdentityProvider'
import { useAsertoApi } from './BaseAsertoApiClient'

export const useAsertoApiWithAuth0Token = (
  baseUrl: string,
  apiHeaderOverrides: Record<string, string> = {}
) => {
  const { getAccessToken, logout } = useIdentity()

  const getAuthorizationHeaderValue = useCallback(async () => {
    return `Bearer ${await getAccessToken()}`
  }, [getAccessToken])

  const onUnauthorized = useCallback(() => logout, [logout])

  return useAsertoApi(baseUrl, apiHeaderOverrides, getAuthorizationHeaderValue, onUnauthorized)
}
