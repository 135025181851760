export const theme = {
  primary: '#277F82',
  primaryBlack: '#121212',
  fullWhite: '#fff',
  lochivar100: '#277F82',
  lochivar90: '#257477',
  lochivar80: '#23696C',
  lochivar70: '#215E60',
  lochivar60: '#1F5355',
  lochivar50: '#1C484A',
  lochivar40: '#1A3E3F',
  lochivar30: '#183334',
  lochivar20: '#162828',
  lochivar10: '#141D1D',
  lochivarAccent1: '#2F989C',
  lochivarAccent2: '#36B1B5',
  lochivarAccent3: '#3ECACF',
  lochivarAccent4: '#67CBCF',
  indogo100: '#376EBF',
  indogo90: '#3364AE',
  indogo80: '#305B9D',
  indogo70: '#2C528B',
  indogo60: '#28497A',
  indogo50: '#254069',
  indogo40: '#213757',
  indogo30: '#1D2D46',
  indogo20: '#192435',
  indogo10: '#161B23',
  indogoAccent1: '#3D79D4',
  indogoAccent2: '#4385E8',
  indogoAccent3: '#4A92FF',
  indogoAccent4: '#4A92FF',
  apple100: '#37BF3D',
  apple90: '#33AE38',
  apple80: '#309D34',
  apple70: '#2C8B30',
  apple60: '#287A2C',
  apple50: '#256927',
  apple40: '#215723',
  apple30: '#1D461F',
  apple20: '#19351B',
  apple10: '#162316',
  appleAccent1: '#3DD444',
  appleAccent2: '#43E84A',
  appleAccent3: '#49FF51',
  appleAccent4: '#7DFF83',
  mojo100: '#BF3737',
  mojo90: '#AE3333',
  mojo80: '#9D3030',
  mojo70: '#8B2C2C',
  mojo60: '#7A2828',
  mojo50: '#692525',
  mojo40: '#572121',
  mojo30: '#461D1D',
  mojo20: '#351919',
  mojo10: '#231616',
  mojoAccent1: '#D43D3D',
  mojoAccent2: '#E84343',
  mojoAccent3: '#FF4A4A',
  mojoAccent4: '#FF7D7D',
  cooper100: '#BF7937',
  cooper90: '#AE6E33',
  cooper80: '#9D6430',
  cooper70: '#9D6430',
  cooper60: '#7A5028',
  cooper50: '#694525',
  cooper40: '#573B21',
  cooper30: '#46311D',
  cooper20: '#352619',
  cooper10: '#231C16',
  cooperAccent1: '#D4853D',
  cooperAccent2: '#E89243',
  cooperAccent3: '#FFA14A',
  cooperAccent4: '#FFBB7D',
  plum100: '#693BCC',
  plum90: '#6137B9',
  plum80: '#5833A7',
  plum70: '#4F2F94',
  plum60: '#462B82',
  plum50: '#3E266F',
  plum40: '#35225C',
  plum30: '#2C1E4A',
  plum20: '#231A37',
  plum10: '#1B1625',
  plumAccent1: '#7441E0',
  plumAccent2: '#7E47F5',
  plumAccent3: '#844AFF',
  plumAccent4: '#A77DFF',
  grey100: '#E7E7E7',
  grey90: '#D0D0D0',
  grey80: '#B8B8B8',
  grey70: '#A0A0A0',
  grey60: '#888888',
  grey50: '#717171',
  grey40: '#595959',
  grey30: '#414141',
  grey20: '#2A2A2A',
  grey10: '#1E1E1E',
  grey: '#4a4a4a',
}
