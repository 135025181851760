import { useQuery } from 'react-query'

import { useAonaClient } from '../clients/AonaClient'
import {
  GetIdentityRequestBody,
  GetIdentityResponse,
  GetUserResponse,
} from '../types/local/authorizer'
import { QueryKeys, reactQueryDefaultOptions } from '../types/local/general'

export const useAonaIdentity = ({ identity }: { identity: string | undefined }) => {
  const { post } = useAonaClient()
  return useQuery(
    [QueryKeys.AonaIdentity, identity],
    () =>
      post<GetIdentityResponse, GetIdentityRequestBody>({
        body: { identity },
        path: 'dir/identities',
      }),
    {
      ...reactQueryDefaultOptions,
      enabled: !!identity,
    }
  )
}

export const useAonaUser = ({ id }: { id: string | undefined }) => {
  const { get } = useAonaClient()
  return useQuery(
    [QueryKeys.AonaIdentity, id],
    () => get<GetUserResponse>({ path: `dir/users/${id}` }),
    {
      ...reactQueryDefaultOptions,
      enabled: !!id,
    }
  )
}
