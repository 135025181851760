import React, { PropsWithChildren, useContext } from 'react'

const processEnvContext = React.createContext<NodeJS.ProcessEnv>(process.env)

export const useProcessEnv = () => {
  return useContext(processEnvContext)
}

const ProcessEnvProvider: React.FC<PropsWithChildren<{ processEnv?: NodeJS.ProcessEnv }>> = ({
  children,
  processEnv,
}) => {
  return !!processEnv ? (
    <processEnvContext.Provider value={processEnv}>{children}</processEnvContext.Provider>
  ) : (
    <>{children}</>
  )
}

export default ProcessEnvProvider
