import React from 'react'
import { Navbar } from 'react-bootstrap'

import AsertoLogo from '../../../assets/aserto-horizontal-white-text.svg'
import separator from './separator.svg'
import { DEFAULT_EXPANSION_BREAKPOINT, NavBarBrand, NavBarContainer, Separator } from './styles'

type expandSteps = 'sm' | 'md' | 'lg' | 'xl'

export type AsertoNavBarProps = {
  children?: React.ReactNode
  logo?: React.ReactNode
  uncollapsableContent?: React.ReactNode
  showBrandSeparator?: boolean
  topPosition?: number
  expand: expandSteps
  expandBreakpoint?: number
  testId?: string
}

const expansionBreakpointsMap: Record<expandSteps, number | undefined> = {
  sm: undefined,
  md: undefined,
  lg: 991,
  xl: DEFAULT_EXPANSION_BREAKPOINT,
}

const DEFAULT_LOGO = <img alt="logo" src={AsertoLogo} />

const AsertoNavBar: React.FC<AsertoNavBarProps> = ({
  children,
  logo = DEFAULT_LOGO,
  showBrandSeparator,
  topPosition,
  expand,
  expandBreakpoint,
  uncollapsableContent,
  ...props
}) => {
  return (
    <NavBarContainer
      $expandBreakpoint={expansionBreakpointsMap[expand] ?? expandBreakpoint}
      $topPosition={topPosition}
      {...props}
    >
      <Navbar className="navbar-dark" collapseOnSelect expand={expand ?? 'xl'}>
        <NavBarBrand>{logo}</NavBarBrand>
        {uncollapsableContent && <Navbar.Text>{uncollapsableContent}</Navbar.Text>}
        {showBrandSeparator && (
          <Separator
            $hideBreakpoint={expansionBreakpointsMap[expand] ?? expandBreakpoint}
            src={separator}
          />
        )}
        {children && (
          <>
            <Navbar.Toggle />
            <Navbar.Collapse>{children}</Navbar.Collapse>
          </>
        )}
      </Navbar>
    </NavBarContainer>
  )
}

export default AsertoNavBar
