import { useCallback, useMemo } from 'react'

import ensureError from '../lib/error/ensureError'
import { useProfile } from '../services/ProfileAndQueryClientProvider'

export function useAsertoApi(
  baseUrl: string,
  apiHeaderOverrides: Record<string, string> = {},
  getAuthorizationHeaderValue?: () => Promise<string>,
  onUnauthorized?: () => void
) {
  const { tenant } = useProfile()

  const generateHeaders = useCallback(
    async (headerOverrides: Record<string, string> = {}) => {
      const headers: Record<string, string> = {
        ...(getAuthorizationHeaderValue
          ? { Authorization: `${await getAuthorizationHeaderValue()}` }
          : {}),
        'Content-Type': 'application/json',
      }

      if (tenant?.id) {
        headers['aserto-tenant-id'] = tenant.id
      }

      Object.assign(headers, apiHeaderOverrides, headerOverrides)

      return headers
    },
    [getAuthorizationHeaderValue, tenant?.id, apiHeaderOverrides]
  )

  const logoutIfUnauthorized = useCallback(
    (status: number): void => {
      // check for an unauthorized status, which indicates an expired token
      if (status === 401) {
        onUnauthorized?.()
      }
    },
    [onUnauthorized]
  )

  const fetchFromAsertoApi = useCallback(
    async (
      method: string,
      path: string,
      queryParams: Record<string, string> = {},
      body: RequestInit['body'] = undefined,
      headerOverrides: Record<string, string> = {},
      abortSignal: AbortSignal | null | undefined
    ) => {
      const [headers] = await Promise.all([generateHeaders(headerOverrides)])
      const queryPart =
        !!queryParams && Object.keys(queryParams).length > 0
          ? `?${new URLSearchParams(queryParams)}`
          : ''
      const url = `${baseUrl}/${path}${queryPart}`

      const response = await fetch(url, {
        method: method || 'GET',
        headers,
        body,
        signal: abortSignal,
      })

      if (!response.ok) {
        let error = ensureError(
          `${response.status}: ${response.statusText} from ${method} ${baseUrl}/${path}`
        )

        try {
          error = ensureError(await response.json())
        } catch (e) {}

        throw error
      }
      logoutIfUnauthorized(response.status)
      return response
    },
    [baseUrl, generateHeaders, logoutIfUnauthorized]
  )

  type FetchParams = {
    abortSignal?: AbortSignal | null
    headerOverrides?: Record<string, string>
    path: string
    queryParams?: Record<string, string>
  }

  type FetchParamsWithBody<T> = FetchParams & {
    body: T
  }
  return useMemo(
    () => ({
      del: async <T>({ abortSignal, headerOverrides = {}, path, queryParams }: FetchParams) => {
        const response = await fetchFromAsertoApi(
          'DELETE',
          path,
          queryParams,
          undefined,
          headerOverrides,
          abortSignal
        )
        return response.json() as Promise<T>
      },

      get: async <T>({ abortSignal, headerOverrides = {}, path, queryParams }: FetchParams) => {
        const response = await fetchFromAsertoApi(
          'GET',
          path,
          queryParams,
          undefined,
          headerOverrides,
          abortSignal
        )
        return response.json() as Promise<T>
      },

      patch: async <Tout, Tin>({
        abortSignal,
        body,
        headerOverrides = {},
        path,
        queryParams,
      }: FetchParamsWithBody<Tin>) => {
        const response = await fetchFromAsertoApi(
          'PATCH',
          path,
          queryParams,
          JSON.stringify(body),
          headerOverrides,
          abortSignal
        )
        return response.json() as Promise<Tout>
      },

      post: async <Tout, Tin>({
        abortSignal,
        body,
        headerOverrides = {},
        path,
        queryParams,
      }: FetchParamsWithBody<Tin>) => {
        const response = await fetchFromAsertoApi(
          'POST',
          path,
          queryParams,
          JSON.stringify(body),
          headerOverrides,
          abortSignal
        )
        return response.json() as Promise<Tout>
      },

      postBlob: async <Tin>({
        abortSignal,
        body,
        headerOverrides = {},
        path,
        queryParams,
      }: FetchParamsWithBody<Tin>) => {
        const response = await fetchFromAsertoApi(
          'POST',
          path,
          queryParams,
          JSON.stringify(body),
          headerOverrides,
          abortSignal
        )
        return response.blob()
      },

      put: async <Tout, Tin>({
        abortSignal,
        body,
        headerOverrides = {},
        path,
        queryParams,
      }: FetchParamsWithBody<Tin>) => {
        const response = await fetchFromAsertoApi(
          'PUT',
          path,
          queryParams,
          JSON.stringify(body),
          headerOverrides,
          abortSignal
        )
        return response.json() as Promise<Tout>
      },
    }),
    [fetchFromAsertoApi]
  )
}

export function v1Api(serviceUrl: string) {
  return `${serviceUrl}/api/v1`
}

export function v2Api(serviceUrl: string) {
  return `${serviceUrl}/api/v2`
}
