type Config = {
  aonaInstanceId: string
  aonaServiceUrl: string
  asertoDirectoryUrl: string
  authorizerServiceUrl: string
  backendServiceUrl: string
  decisionLogsServiceUrl: string
  ds0DirectoryUrl: string
  fullStoryOrgId: string
  funnelServiceUrl: string
  isProduction: boolean
  oauthServiceUrl: string
  readmeIoKey: string
  relayServiceUrl: string
  sentryDsn: string
  tenantServiceUrl: string
}

export const emptyConfig: Config = {
  aonaInstanceId: '',
  aonaServiceUrl: '',
  authorizerServiceUrl: '',
  backendServiceUrl: '',
  decisionLogsServiceUrl: '',
  ds0DirectoryUrl: '',
  fullStoryOrgId: '',
  funnelServiceUrl: '',
  isProduction: true,
  oauthServiceUrl: '',
  readmeIoKey: '',
  sentryDsn: '',
  tenantServiceUrl: '',
  asertoDirectoryUrl: '',
  relayServiceUrl: '',
} as const

export default Config
