import styled from 'styled-components'

import PaddedContent from '../../components/common/PaddedContent'

export const CreateTenantContainer = styled(PaddedContent)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

export const ButtonContainer = styled.div`
  margin-top: 20px;
`

export const CardTextUpper = styled.div`
  font-weight: bold;
  padding: 20px 20px 0px 20px;
  color: ${({ theme }) => theme.grey100};
`

export const InputContainer = styled.div`
  padding: 20px;
  width: 100%;
`
