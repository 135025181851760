import React, { PropsWithChildren, useCallback, useContext, useState } from 'react'
import * as Sentry from '@sentry/react'

import ensureError from '../../lib/error/ensureError'
import ErrorModal from './ErrorModal'

type ErrorModalProviderContextProps = {
  clearError: () => void
  isErrorVisible: boolean
  showError: (error: unknown) => void
}

type ErrorModalProviderProps = PropsWithChildren<{}>

const ErrorContext = React.createContext<ErrorModalProviderContextProps>({
  clearError: () => {},
  isErrorVisible: false,
  showError: () => {},
})

export const useShowError = () => {
  const { showError } = useContext(ErrorContext)
  return showError
}

export const useIsErrorModalVisibile = () => {
  const { isErrorVisible } = useContext(ErrorContext)
  return isErrorVisible
}

const ErrorModalProvider: React.FC<ErrorModalProviderProps> = ({ children }) => {
  const [error, setError] = useState<Error | null>(null)
  const clearError = useCallback(() => setError(null), [])
  const showError = useCallback((error: unknown) => {
    const ensuredError = ensureError(error)
    Sentry.captureException(ensuredError)
    setError(ensuredError)
  }, [])

  return (
    <ErrorContext.Provider value={{ clearError, showError, isErrorVisible: !!error }}>
      <ErrorModal error={error} onClose={() => setError(null)} />
      {children}
    </ErrorContext.Provider>
  )
}

export default ErrorModalProvider
