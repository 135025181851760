import Validator, { Validity } from './validator'

// Must start with a-z, and must be alphanumeric (or dashes) 6-30 characters
const ASERTO_NAME_REGEXP = /^[a-z][a-z0-9-]{3,29}$/
const ASERTO_NAME_FRAGMENT_REGEXP = /^[a-z][a-z0-9-]{0,29}$/

const getValidityOf = (name: string): Validity => {
  if (ASERTO_NAME_REGEXP.test(name)) {
    return 'VALID'
  } else if (name.length === 0 || ASERTO_NAME_FRAGMENT_REGEXP.test(name)) {
    return 'VALID_FRAGMENT'
  }
  return 'INVALID'
}

const isValid = (name: string) => getValidityOf(name) === 'VALID'

const AsertoNameValidator: Validator<string> = {
  getValidityOf,
  isValid,
  ruleText: 'Start with a letter. Must be 4 to 30 lowercase alphanumeric characters or dashes.',
}

export default AsertoNameValidator
