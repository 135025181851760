import { useMemo } from 'react'

import EnvConfig from '../../types/local/envConfig'
import { useProcessEnv } from '../ProcessEnvProvider'

export const useEnvConfig = (): EnvConfig => {
  const env = useProcessEnv()
  const envConfig = useMemo(
    () => ({
      audience: env.REACT_APP_AUTH0_AUDIENCE || '',
      clientId: env.REACT_APP_AUTH0_CLIENTID || '',
      discoveryServiceUrl: env.REACT_APP_DISCOVERY_SERVICE_URL || '',
      domain: env.REACT_APP_AUTH0_DOMAIN || '',
      environment: env.REACT_APP_ENVIRONMENT || '',
      gitSha: env.REACT_APP_GIT_SHA || '',
      gitVersionTag: env.REACT_APP_GIT_VERSION_TAG || '',
      isDocker: !!env.REACT_APP_DOCKER,
      websiteUrl: env.REACT_APP_ASERTO_WEBSITE_URL || '',
    }),
    [
      env.REACT_APP_ASERTO_WEBSITE_URL,
      env.REACT_APP_AUTH0_AUDIENCE,
      env.REACT_APP_AUTH0_CLIENTID,
      env.REACT_APP_AUTH0_DOMAIN,
      env.REACT_APP_DISCOVERY_SERVICE_URL,
      env.REACT_APP_DOCKER,
      env.REACT_APP_ENVIRONMENT,
      env.REACT_APP_GIT_SHA,
      env.REACT_APP_GIT_VERSION_TAG,
    ]
  )

  return envConfig
}
