import { UseMutationResult, UseQueryResult } from 'react-query'

import AccountNameValidator from '../../lib/validation/AccountNameValidator'
import {
  ClaimTenantRequestBody,
  ClaimTenantResponse,
  RpcStatus,
  TenantAvailableResponse,
} from '../../types/local/tenant'
import { VisualState, VisualStates } from './VisualStates'

export const getVisualState = (
  previousVisualState: VisualState,
  accountName: string,
  tenantNameAvailableQueryResult: UseQueryResult<TenantAvailableResponse>,
  claimTenantMutationResult: UseMutationResult<
    ClaimTenantResponse,
    RpcStatus,
    ClaimTenantRequestBody
  >
) => {
  if (!claimTenantMutationResult.isIdle) {
    return getSubmittedFormValidationState(claimTenantMutationResult)
  }

  if (!AccountNameValidator.isValid(accountName)) {
    return VisualStates.INVALID
  }

  const { isLoading: isValidating } = tenantNameAvailableQueryResult

  if (isValidating) {
    return previousVisualState
  }

  return getValidationRequestValidationState(tenantNameAvailableQueryResult)
}

function getValidationRequestValidationState(
  tenantNameAvailableQueryResult: UseQueryResult<TenantAvailableResponse>
) {
  const { data: validationResult, error: validationError } = tenantNameAvailableQueryResult

  if (validationError) {
    return VisualStates.INVALID
  }

  switch (validationResult?.availability) {
    case 'TENANT_AVAILABILITY_AVAILABLE':
      return VisualStates.VALID
    case 'TENANT_AVAILABILITY_UNAVAILABLE':
      return VisualStates.INVALID_UNAVAILABLE
    case 'TENANT_AVAILABILITY_RESERVED':
      return VisualStates.INVALID_RESERVED
    case 'TENANT_AVAILABILITY_INVALID':
      return VisualStates.INVALID
    case 'TENANT_AVAILABILITY_MARKED_DELETED':
      return VisualStates.INVALID_MARKED_DELETED
    default:
      return VisualStates.INVALID_UNKNOWN
  }
}

function getSubmittedFormValidationState(
  claimTenantMutationResult: UseMutationResult<
    ClaimTenantResponse,
    RpcStatus,
    ClaimTenantRequestBody
  >
) {
  const { error: claimError, isLoading: isClaiming } = claimTenantMutationResult

  if (isClaiming) {
    return VisualStates.CLAIMING
  }

  if (claimError) {
    return {
      ...VisualStates.CLAIMING_FAILED,
      errorMessage: claimError.message || VisualStates.CLAIMING_FAILED.errorMessage,
    }
  }

  return VisualStates.CLAIMED
}
