import { useConfig } from '../services/ConfigProvider'
import { useAsertoApiWithAuth0Token } from './AsertoApiWithAuth0Token'
import { v1Api } from './BaseAsertoApiClient'

export const useAonaClient = () => {
  const { aonaServiceUrl } = useConfig()
  const { aonaInstanceId } = useConfig()

  return useAsertoApiWithAuth0Token(v1Api(aonaServiceUrl), { 'aserto-tenant-id': aonaInstanceId })
}
