import { useMutation, useQuery } from 'react-query'

import { useTenantClient } from '../clients/TenantClient'
import {
  MutationKeys,
  QueryKeys,
  reactQueryDefaultOptions,
  ReactQueryMutationHooks,
  useMutationOptions,
} from '../types/local/general'
import {
  CreatePolicyBuilderRequestBody,
  CreatePolicyBuilderResponse,
  DeletePolicyBuilderResponse,
  ListPolicyBuildersResponse,
} from '../types/local/tenant'

export const useCreatePolicyBuilder = (
  reactQueryHooks: ReactQueryMutationHooks<
    CreatePolicyBuilderResponse,
    { overrideSecrets: boolean } & CreatePolicyBuilderRequestBody
  > = {}
) => {
  const mutationOptions = useMutationOptions(reactQueryHooks, [QueryKeys.PolicyBuilderData])
  const { post } = useTenantClient()

  return useMutation(
    [MutationKeys.CreatePolicyBuilder],
    ({ overrideSecrets, ...body }) =>
      post({
        body,
        path: `policybuilder`,
        queryParams: {
          force_reconnect: String(overrideSecrets),
        },
      }),
    mutationOptions
  )
}

export const useListPolicyBuilders = ({
  policyRepo,
  policyOrganization,
}: {
  policyRepo?: string
  policyOrganization?: string
}) => {
  const { get } = useTenantClient()
  return useQuery(
    [QueryKeys.PolicyBuilderData, policyRepo, policyOrganization],
    () =>
      get<ListPolicyBuildersResponse>({
        path: `policybuilder`,
        queryParams: {
          registry_repo: policyRepo!,
          registry_org: encodeURIComponent(policyOrganization!),
        },
      }),
    {
      ...reactQueryDefaultOptions,
      enabled: !!policyRepo && !!policyOrganization && !!policyRepo && !!policyOrganization,
    }
  )
}

export const useDeletePolicyBuilder = ({
  policyOrganization,
  policyRepo,
  reactQueryHooks = {},
}: {
  policyOrganization: string
  policyRepo: string
  reactQueryHooks?: ReactQueryMutationHooks<DeletePolicyBuilderResponse, { id: string }>
}) => {
  const mutationOptions = useMutationOptions(reactQueryHooks, [
    QueryKeys.PolicyBuilderData,
    policyRepo,
    policyOrganization,
  ])
  const { del } = useTenantClient()
  return useMutation(
    [MutationKeys.DeletePolicyBuilder],
    ({ id }) => {
      return del({ path: `policybuilder/${id}` })
    },
    mutationOptions
  )
}
