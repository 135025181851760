import styled from 'styled-components'

import { theme } from '../../../theme'

export const StyledSectionHeader = styled.h1`
  font-size: inherit;
  font-weight: 600;
`

export const AccentText = styled.span`
  color: ${theme.grey100};
`
