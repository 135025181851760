import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import SpinnerFull from './SpinnerFull'

type SpinnerProviderContextProps = {
  setSpinnerVisibility: (id: string, show: boolean) => void
  isSpinnerVisible: boolean
}

type SpinnerProviderProps = PropsWithChildren<{}>

const SpinnerContext = React.createContext<SpinnerProviderContextProps>({
  setSpinnerVisibility: () => {},
  isSpinnerVisible: false,
})

export const SpinnerToggle: React.FC<{ show?: boolean }> = ({ show = true }) => {
  const [randomId] = useState(Math.random().toString())
  const { setSpinnerVisibility } = useContext(SpinnerContext)
  useEffect(() => setSpinnerVisibility(randomId, show), [randomId, show, setSpinnerVisibility])
  return null
}

export const useIsSpinnerVisible = () => {
  const { isSpinnerVisible } = useContext(SpinnerContext)
  return isSpinnerVisible
}

const SpinnerProvider: React.FC<SpinnerProviderProps> = ({ children }) => {
  const [spinnerSet, setSpinnerSet] = useState<Set<string>>(new Set())
  const showSpinner = useMemo(() => spinnerSet.size !== 0, [spinnerSet.size])
  const setSpinnerVisibility = useCallback((id: string, show: boolean) => {
    show
      ? setSpinnerSet((prev) => new Set([...prev, id]))
      : setSpinnerSet((prev) => new Set([...prev].filter((item) => item !== id)))
    return () => setSpinnerSet((prev) => new Set([...prev].filter((item) => item !== id)))
  }, [])

  return (
    <SpinnerContext.Provider value={{ setSpinnerVisibility, isSpinnerVisible: showSpinner }}>
      {showSpinner && <SpinnerFull />}
      {children}
    </SpinnerContext.Provider>
  )
}

export default SpinnerProvider
